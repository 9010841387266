<template>
  <div>
    <BaseModal
      name="entregas-opcoes"
      @shown="openModal"
      size="md"
      title="📦 Entrega de conteúdo"
    >
      <ul class="opcoes-entrega">
        <li
          v-if="outrasOpcoes == false"
          @click="openIntegracao('modal-arquivos')"
        >
          <div class="grid-content">
            <img src="@/assets/img/icons/file-icon.svg" />
            <div class="infos">
              <h5>Adicionar Arquivos</h5>
              <p>PDF, documentos, e-books</p>
            </div>
          </div>
        </li>
        <li
          @click="outrasOpcoes = !outrasOpcoes"
          :class="{ ativo: outrasOpcoes }"
        >
          <div class="grid-content">
            <img
              src="@/assets/img/icons/long-arrow.svg"
              @click="outrasOpcoes = false"
              class="voltar-opcoes"
              v-if="outrasOpcoes"
            />
            <img src="@/assets/img/icons/Outros.svg" v-else />

            <div class="infos" v-if="outrasOpcoes">
              <h5>Voltar para outras opções</h5>
            </div>

            <div class="infos" v-else>
              <h5>Sistemas externos</h5>
              <p>Memberkit, Webhook, ActiveCampaign, GoogleSheets</p>
            </div>
          </div>
        </li>

        <div
          class="outras-opcoes"
          v-if="outrasOpcoes"
          :class="{ ativo: outrasOpcoes }"
        >
          <li @click="openIntegracao('modal-googlesheets')">
            <div class="grid-content">
              <img
                src="@/assets/img/icons/googlesheets.svg"
                class="logo-entrega-act"
              />
              <h5>Google Sheets</h5>
            </div>
          </li>
          <li @click="openIntegracao('modal-webhook')">
            <div class="grid-content">
              <img src="@/assets/img/icons/webhook-icon.svg" />
              <h5>Webhook</h5>
            </div>
          </li>
        </div>
      </ul>
      <template v-slot:footer="{ cancel }">
        <BaseButton variant="secondary_outline" @click="cancel">
          Cancelar
        </BaseButton>
      </template>
    </BaseModal>

    <googlesheets :id_product="id_product" @close="atualizarSheets" />
    <memberkit :id_product="id_product" @close="atualizarMemberkit" />
    <webhook :id_product="id_product" @close="atualizarWebhook" />
    <activecampaign :id_product="id_product" @close="atualizarActive" />
    <arquivos :id_product="id_product" @close="atualizarArquivos" />
    <infusionsoft :id_product="id_product" @close="atualizarInfusionsoft" />
    <voxuy :id_product="id_product" @close="atualizarVoxuy" />
    <leadlovers :id_product="id_product" @close="atualizarLeadLovers" />
  </div>
</template>

<script>
import googlesheets from "./googlesheets";
import memberkit from "./memberkit";
import webhook from "./webhook";
import activecampaign from "./activecampaign";
import arquivos from "./entregaArquivos.vue";
import infusionsoft from "./infusionsoft.vue";
import voxuy from "./voxuy.vue";
import leadlovers from "./leadLovers.vue";

export default {
  data() {
    return {
      outrasOpcoes: false,
    };
  },
  components: {
    memberkit,
    googlesheets,
    webhook,
    activecampaign,
    arquivos,
    infusionsoft,
    voxuy,
    leadlovers,
  },
  props: {
    id_product: {
      type: Number,
    },
  },
  methods: {
    // funcao para emitir o evento para a view editProd e atualizar a listagem ARQUIVOS
    atualizarArquivos(data) {
      this.$emit("files-list", data);
    },

    // funcao para emitir o evento para a view editProd e atualizar a listagem ACTIVE
    atualizarActive() {
      this.$emit("active-list");
    },

    // funcao para emitir o evento para a view editProd e atualizar a listagem SHEETS
    atualizarGoogleSheets() {
      this.$emit("sheets-list");
    },

    // funcao para emitir o evento para a view editProd e atualizar a listagem MEMBERKIT
    atualizarMemberkit() {
      this.$emit("memberkit-list");
    },

    // funcao para emitir o evento para a view editProd e atualizar a listagem WEBHOOK
    atualizarWebhook() {
      this.$emit("webhook-list");
    },

    atualizarSheets() {
      this.$emit("sheets-list");
    },

    atualizarInfusionsoft() {
      this.$emit("infusionsoft-list");
    },

    atualizarVoxuy() {
      this.$emit("voxuy-list");
    },
    atualizarLeadLovers() {
      this.$emit("leadlovers-list");
    },

    // helper abrir modal
    openIntegracao(modal) {
      this.$bvModal.show(modal);
      this.$bvModal.hide("entregas-opcoes");
    },

    // abrindo modal e setando valores
    openModal() {},
  },
};
</script>

<style scoped>
p {
  margin: 0;
}

.opcoes-entrega {
  display: flex;
  flex-direction: column;
}
.opcoes-entrega li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 30px;
  height: 70px;
  border-radius: 5px;
  background: rgba(247, 247, 247, 0.5);
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s;
}

.opcoes-entrega li {
  padding: 30px;
  height: 90px;
}

.opcoes-entrega li .grid-content {
  display: grid;
  grid-template-columns: 140px 1fr;
  align-items: center;
}

.opcoes-entrega li div img {
  width: 20px;
  margin-right: 30px;
  filter: invert(50%);
}
.opcoes-entrega li .voxuy img {
  width: 80px;
}

.opcoes-entrega li .infos h5,
.grid-content h5 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}
.grid-content h5 {
  margin: 0;
}
.opcoes-entrega li .infos p {
  font-size: 13px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
.opcoes-entrega li div img.logo-entrega {
  width: 60px;
}

.opcoes-entrega li div img.logo-entrega-act {
  width: 120px;
}

.opcoes-entrega li:hover {
  font-weight: 600;
  background: #f7f7f7;
}

.icon-lixeira:hover,
.icon-editar:hover {
  filter: invert(0) !important;
}

.opcoes-entrega li + li {
  margin-top: 10px;
}

/* // outras opcoes */
.opcoes-entrega li.ativo {
  background: rgba(0, 0, 210, 0.2);
}
.opcoes-entrega li.ativo div h5,
.opcoes-entrega li.ativo div p {
  color: #2a63ab;
}
.opcoes-entrega li.ativo div img {
  filter: brightness(0) invert(1);
}
.outras-opcoes {
  margin: 10px 0;
  display: none;
  animation: showTop 0.3s forwards;
}
.outras-opcoes.ativo {
  display: block;
}
.voltar-opcoes {
  filter: none !important;
}
@media screen and (max-width: 768px) {
  .opcoes-entrega li .grid-content {
    display: flex;
  }
  .opcoes-entrega li .grid-content img {
    display: none;
  }
}
</style>
