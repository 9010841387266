<template>
  <BaseModal
    name="modal-activecampaing"
    size="lg"
    title="Adicione a entrega via ActiveCampaign"
    @shown="openModal"
  >
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <section class="container-arquivos" v-else>
      <div class="lista-entrega lista-arquivos mt-4">
        <div>
          <h4 class="title-inter">• ActiveCampaign</h4>
          <div class="d-flex mb-2" data-anima="top">
            <BaseButton
              variant="info3"
              class="gerencia-conexoes mb-3"
              @click="gerenciar = true"
              v-if="gerenciar === false"
              >Gerenciar Conexão</BaseButton
            >
            <BaseButton
              variant="info3"
              class="gerencia-conexoes mb-3"
              @click="gerenciar = false"
              v-else
              >Voltar</BaseButton
            >
          </div>
          <div
            v-if="activecampaign.length === 0 && gerenciar === false"
            data-anima="top"
          >
            <p>
              Verificamos que não há conexões com o ActiveCampaign vinculadas em
              sua conta.
            </p>
            <p>
              Por gentileza ajuste a conexão para seguir o fluxo e adicionar uma
              integração com o ActiveCampaign.
            </p>
            <br />
            <p>Clique no botão acima para gerenciar sua conexão</p>
          </div>
          <b-row v-if="gerenciar === true">
            <b-col cols="12" md="6" data-anima="top">
              <b-form-group label="URL" label-for="api_key">
                <b-form-input
                  id="api_key"
                  name="api_key"
                  v-model="connection_base_url"
                  v-validate="'required'"
                  placeholder="URL"
                  type="text"
                ></b-form-input>
                <b-form-invalid-feedback
                  :state="submit ? !errors.has('connection_base_url') : null"
                >
                  Por favor, insira a URL.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" data-anima="top">
              <b-form-group label="API Key" label-for="api_key">
                <b-form-input
                  id="api_key"
                  name="api_key"
                  placeholder="API Key"
                  v-model="connection_api_key"
                  v-validate="'required'"
                  type="text"
                ></b-form-input>
                <b-form-invalid-feedback
                  :state="submit ? !errors.has('connection_api_key') : null"
                >
                  Por favor, insira a API Key.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-col
            cols="12"
            class="mt-4"
            data-anima="top"
            v-if="activecampaign.length !== 0 && gerenciar === false"
          >
            <h6 class="titulo-acao">Quando acontecer o(s) evento(s)</h6>
            <div class="grid-events">
              <b-form-group label="Vendas" label-for="check">
                <b-form-checkbox
                  size="md"
                  switch
                  class="check-box mb-1"
                  v-for="(op, index) in options_sales"
                  name="check"
                  v-model="selectEvent[index]"
                  :key="op.value"
                  @change="triggerChanged(op.value)"
                >
                  <p class="info-checkbox">{{ op.text }}</p>
                  <div
                    v-show="op.value == 'saleWaitingPayment' && selectEvent[5]"
                    data-anima="top"
                    class="mt-3 mb-3"
                  >
                    <b-form-checkbox
                      size="md"
                      switch
                      v-for="(sub, index) in options_salesWaiting"
                      class="check-box mb-2"
                      :name="`check-waiting-${index}`"
                      :id="`check-waiting-${index}`"
                      :key="sub.value"
                      v-model="selectEventWaiting[index]"
                      @change="
                        subTriggerChanged(sub.value, 'saleWaitingPayment')
                      "
                    >
                      <p class="info-checkbox">{{ sub.text }}</p>
                    </b-form-checkbox>
                  </div>
                </b-form-checkbox>
              </b-form-group>
              <b-form-group label="Assinatura" label-for="check">
                <b-form-checkbox
                  size="md"
                  switch
                  class="check-box mb-2"
                  name="check"
                  v-for="op in options_sub"
                  :key="op.value"
                  @change="triggerChanged(op.value)"
                >
                  <p class="info-checkbox">{{ op.text }}</p>
                </b-form-checkbox>
              </b-form-group>
              <b-form-group label="Ação" label-for="check" data-anima="top">
                <b-form-checkbox
                  size="md"
                  switch
                  class="check-box mb-2"
                  name="check"
                  v-for="op in options_callback"
                  :key="op.value"
                  v-model="op.type"
                  @change="triggerCallbackChanged(op)"
                >
                  <p class="info-checkbox">{{ op.text }}</p>
                </b-form-checkbox>
              </b-form-group>
              <b-form-group label="Checkout" label-for="check">
                <b-form-checkbox
                  size="md"
                  switch
                  class="check-box mb-2"
                  name="check"
                  v-for="op in options_checkout"
                  :key="op.value"
                  @change="triggerChanged(op.value)"
                >
                  <p class="info-checkbox">{{ op.text }}</p>
                </b-form-checkbox>
              </b-form-group>
            </div>
          </b-col>
          <span>
            <b-col
              cols="12"
              v-if="activecampaign.length !== 0 && gerenciar === false"
            >
              <b-form-group
                label="Selecione uma Lista"
                label-for="form_list_active"
              >
                <multiselect
                  v-model="lista"
                  v-if="multiselect"
                  :options="lista_options"
                  :value="lista"
                  :multiple="false"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="true"
                  placeholder="Selecione uma Lista"
                  label="text"
                  track-by="text"
                  selectLabel=""
                  deselectLabel=""
                  selectedLabel="✔️"
                  id="multi_select_list_active"
                  name="list_active"
                  :custom-label="customLabel"
                >
                </multiselect>
                <b-form-invalid-feedback :state="!errors.has('list_active')">
                  Nenhuma lista selecionada
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </span>
          <b-col
            cols="12"
            v-if="activecampaign.length !== 0 && gerenciar === false"
            data-anima="top"
          >
            <b-form-group
              label="Selecione uma Tag"
              label-for="form_list_active"
            >
              <multiselect
                v-model="tag"
                v-if="multiselect"
                :options="tag_options"
                :value="tag"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Selecione uma Tag"
                label="text"
                track-by="text"
                selectLabel=""
                deselectLabel=""
                selectedLabel="✔️"
                id="multi_select_list_active"
                name="list_active"
                :custom-label="customLabelTags"
              >
              </multiselect>
              <b-form-invalid-feedback :state="!errors.has('list_active')">
                Nenhuma tag selecionada
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </div>
      </div>
    </section>

    <!-- Modal conexoes -->
    <Conexoes @close="fetchConnection" />

    <template v-slot:footer="{ cancel }">
      <BaseButton
        :disabled="loading"
        variant="link-info"
        class="mr-4"
        @click="cancel"
      >
        Cancelar
      </BaseButton>
      <BaseButton
        v-if="gerenciar === false && activecampaign.length !== 0"
        variant="info3"
        :disabled="loading"
        @click="createCallback"
      >
        Adicionar
      </BaseButton>
      <BaseButton
        v-if="gerenciar === true"
        variant="info3"
        :disabled="loading"
        @click="createIntegrationAC"
      >
        Salvar Conexão
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import Conexoes from "../../MyAccount/modals/Conexoes";

// services:
import ConnectionService from "@/services/resources/ActiveCampaignService";
const serviceConnection = ConnectionService.build();
import ActiveCampaignService from "@/services/resources/ActiveCampaignService";
const serviceActiveCConnection = ActiveCampaignService.build();
import Multiselect from "vue-multiselect";
import CallbackService from "@/services/resources/CallbackService";
const serviceCallback = CallbackService.build();
import { convertTrigger } from "@/helpers.js";

export default {
  data() {
    return {
      loading: false,
      submit: false,
      activecampaign: {},
      connection_name: null,
      connection_api_key: null,
      connection_base_url: null,
      lista_options: [],
      tag_options: [],
      tag: null,
      select_callback: "cadastrarActiveCampaign",
      trigger: [],
      callback: [],
      lista: null,
      gerenciar: false,
      selectEventWaiting: [],
      selectEvent: [],
      sub_trigger: [],
      sub_trigger2: [],
      multiselect: true,
      options_sales: [
        {
          value: "salePaid",
          text: "Venda Paga",
        },
        {
          value: "saleRefused",
          text: "Venda Recusada",
        },
        {
          value: "saleRefunded",
          text: "Venda Reembolsada",
        },
        {
          value: "saleChargedback",
          text: "Chargeback realizado",
        },
        {
          value: "saleUnpaid",
          text: "Venda não paga",
        },
        {
          value: "saleWaitingPayment",
          text: "Venda aguardando pagamento",
        },
      ],
      options_salesWaiting: [
        {
          value: "TODOS",
          text: "Todos",
        },
        {
          value: "BOLETO",
          text: "Boleto",
        },
        {
          value: "PIX",
          text: "PIX",
        },
      ],
      options_sub: [
        {
          value: "contractUnpaid",
          text: "Assinatura não paga",
        },
        {
          value: "contractPaid",
          text: "Assinatura paga",
        },
        {
          value: "contractTrialing",
          text: "Assinatura em Trial",
        },
        {
          value: "contractPendingPayment",
          text: "Assinatura aguardando pagamento",
        },
        {
          value: "contractEnded",
          text: "Assinatura Finalizada",
        },
        {
          value: "contractCanceled",
          text: "Assinatura Cancelada",
        },
      ],
      options_checkout: [
        {
          value: "checkoutAbandoned",
          text: "Checkout Abandonado",
        },
      ],
      options_callback: [
        {
          value: "cadastrarActiveCampaign",
          text: "Cadastrar",
          type: true,
        },
        {
          value: "removerActiveCampaign",
          text: "Remover",
          type: false,
        },
      ],
    };
  },
  props: {
    id_product: {
      type: Number,
    },
  },
  components: {
    Conexoes,
    Multiselect,
  },
  methods: {
    subTriggerChanged(v, type) {
      const include = this.sub_trigger2.includes(v);

      if (v == "TODOS") {
        if (!include) {
          this.selectEventWaiting = [];
          this.options_salesWaiting.forEach((_, index) => {
            if (index > 0) {
              this.init_click = true;
              if (!this.selectEventWaiting[index]) {
                document.querySelector(`#check-waiting-${index}`).click();
              }
            }
          });
        } else {
          this.options_salesWaiting.forEach((_, index) => {
            if (index > 0) {
              if (this.selectEventWaiting[index]) {
                document.querySelector(`#check-waiting-${index}`).click();
              }
            }
          });
        }
      }

      if (include) {
        this.sub_trigger2.splice(this.sub_trigger2.indexOf(v), 1);
      } else {
        this.sub_trigger2.push(v);
      }
    },
    triggerCallbackChanged(v) {
      if (v.value === "cadastrarActiveCampaign") {
        this.options_callback[1].type = false;
        this.options_callback[0].type = true;
      } else {
        this.options_callback[0].type = false;
        this.options_callback[1].type = true;
      }

      if (this.options_callback[0].type === true) {
        this.select_callback = v.value;
      }

      if (this.options_callback[1].type === true) {
        this.select_callback = v.value;
      }
    },
    resetInputs() {
      this.activecampaign = {};
      this.connection_name = null;
      this.connection_api_key = null;
      this.connection_base_url = null;
      this.lista_options = [];
      this.tag_options = [];
      this.tag = null;
      this.sub_trigger2 = [];
      this.sub_trigger = [];
      this.selectEvent = [];
      this.selectEventWaiting = [];
      this.options_callback[0].type = true;
      this.options_callback[1].type = false;
      this.select_callback = "cadastrarActiveCampaign";
      this.trigger = [];
      this.lista = null;
      this.gerenciar = false;
    },
    // adicionar uma nova callback
    createCallback() {
      return new Promise((resolve) => {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.submit = true;
            this.loading = true;
            if (this.lista !== null || this.tag !== null) {
              var list_ = "";
              var listname_ = "";
              if (this.lista !== null) {
                list_ = this.lista.id;
                listname_ = this.lista.name;
              }

              var tag_ = "";
              var tagname_ = "";
              if (this.tag !== null) {
                tag_ = this.tag.id;
                tagname_ = this.tag.tag;
              }

              let data = {
                product_id: this.id_product,
                trigger: this.connection_api_key,
                callback: this.select_callback,
                type: "activecampaign",
                list_id: list_,
                tag_id: tag_,
                list_name: listname_,
                tag_name: tagname_,
              };

              console.log(this.trigger);
              if (this.trigger.length) {
                this.trigger.forEach(async (event) => {
                  data.trigger = "";
                  data.trigger = event;
                  if (event === "saleWaitingPayment") {
                    let sub_trigger_check = false;

                    this.selectEventWaiting.map(async (item, index) => {
                      if (item && !this.selectEventWaiting[0]) {
                        sub_trigger_check = true;
                        await this.postCallback(
                          {
                            ...data,
                            sub_trigger: this.options_salesWaiting[index].value,
                          },
                          this.options_salesWaiting[index].text
                        );
                      }
                    });

                    if (!sub_trigger_check) {
                      await this.postCallback(
                        { ...data, trigger: event },
                        event
                      );
                    }
                  } else {
                    await this.postCallback({ ...data, trigger: event }, event);
                  }
                  resolve(true);
                });
              } else {
                this.loading = false;
                this.$bvToast.toast(`Selecione pelo menos um evento`, {
                  title: "ActiveCampaign",
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
                resolve(false);
              }
            } else {
              this.loading = false;
              this.$bvToast.toast(`Selecione uma Lista ou Tag para continuar`, {
                title: "ActiveCampaign",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
              resolve(false);
            }
          } else {
            resolve(false);
          }
        });
      });
    },

    async postCallback(data, event) {
      serviceCallback
        .create(data)
        .then(() => {
          this.$bvToast.toast(
            `[${convertTrigger(event)}] adicionado com sucesso`,
            {
              title: "ActiveCampaign",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            }
          );
          this.resetInputs();
          this.$bvModal.hide("modal-activecampaing");
          setTimeout(() => {
            this.$emit("close");
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.submit = false;
          this.loading = false;
        });
    },
    createIntegrationAC() {
      this.submit = true;
      this.loading = true;
      this.$validator.validateAll("connection").then((result) => {
        if (result) {
          let data = {
            title: this.connection_name,
            "api-token": this.connection_api_key,
            base_url: this.connection_base_url,
          };
          serviceActiveCConnection
            .create(data)
            .then((response) => {
              this.$bvToast.toast("Conexão salva com sucesso!", {
                title: "Conexões",
                variant: "success",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.connection_name = null;
              this.connection_name = null;
              this.connection_base_url = null;
              this.plataform = null;
              this.gerenciar = false;
              this.fetchConnection();
              this.fetchLists();
              this.fetchTags();
            })
            .catch((err) => {
              console.log(err);
              this.$bvToast.toast("Erro ao salvar a Conexão!", {
                title: "Conexões",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .finally(() => {
              this.submit = false;
              this.loading = false;
            });
        }
      });
    },
    // checkbox dos eventos
    triggerChanged(v) {
      const include = this.trigger.includes(v);

      if (v == "saleWaitingPayment") {
        if (!include) {
          document.querySelector(`#check-waiting-0`).click();
        } else {
          this.options_salesWaiting.forEach((_, index) => {
            if (this.selectEventWaiting[index]) {
              document.querySelector(`#check-waiting-${index}`).click();
            }
          });
        }
      }

      if (include) {
        this.trigger.splice(this.trigger.indexOf(v), 1);
      } else {
        this.trigger.push(v);
      }
    },
    customLabelTags({ tag }) {
      return `${tag}`;
    },
    customLabel({ name }) {
      return `${name}`;
    },
    fetchLists() {
      this.multiselect = false;
      serviceConnection
        .read("/lists")
        .then((response) => {
          this.lista_options = response.lists;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.multiselect = true;
          this.loading = false;
        });
    },
    fetchTags() {
      this.multiselect = false;
      serviceConnection
        .read("/tags")
        .then((response) => {
          this.tag_options = response.tags;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.multiselect = true;
          this.loading = false;
        });
    },
    fetchConnection() {
      serviceConnection
        .search()
        .then((response) => {
          this.activecampaign = response;
          this.connection_name = response.title;
          (this.connection_api_key = response.apiToken),
            (this.connection_base_url = response.base_url);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // abrindo modal e setando valores
    openModal() {
      this.loading = true;
      // puxando as conexões do activecampaign
      this.fetchConnection();
      this.fetchLists();
      this.fetchTags();
      this.resetInputs();
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
.title-inter {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #2a63ab;
}
.form-group + .form-group {
  margin-top: 15px;
}
.gerencia-conexoes {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}

.codigo-verifica p {
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
  margin-bottom: 20px;
}
.sem-codigo {
  font-size: 14px;
  color: #333;
}
.sem-codigo:hover {
  font-weight: normal;
}
.grid-events {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 20px 0 10px;
}
.titulo-acao {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
}

.check-box .info-checkbox {
  font-size: 13px;
  font-weight: 500;
  margin-left: 5px;
  cursor: pointer;
  max-width: 250px;
  margin-top: 15px !important;
}
</style>
