<template>
  <BaseModal
    name="modal-webhook"
    size="lg"
    title="Adicione a entrega via Webhook"
  >
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <section class="container-arquivos" v-else>
      <div class="lista-entrega lista-arquivos mt-4">
        <div>
          <h4 class="title-inter">• Webhook</h4>
          <div class="d-flex justify-content-end">
            <div>
              <BaseButton
                variant="secondary_outline"
                class="link-documentacao"
                @click="openDocumentacaoWebHook"
                >Documentação</BaseButton
              >
            </div>
          </div>
          <b-form novalidate>
            <h4 class="title-inter">Quando acontecer o(s) evento(s)</h4>
            <div class="grid-events">
              <b-form-group label="Vendas" label-for="check">
                <b-form-checkbox
                  size="md"
                  switch
                  class="check-box mb-2"
                  v-for="(op, index) in events.options_sales"
                  :name="`check-${index}`"
                  :id="`check-${index}`"
                  v-model="selectEvent[index]"
                  :key="op.value"
                  @change="triggerChanged(op.value)"
                >
                  <p class="info-checkbox">{{ op.text }}</p>
                  <div
                    v-show="op.value == 'saleRefused' && selectEvent[1]"
                    data-anima="top"
                    class="mt-3 mb-3"
                  >
                    <b-form-checkbox
                      size="md"
                      switch
                      v-for="(sub, index) in events.options_salesRefused"
                      class="check-box mb-2"
                      :name="`check-refunsed-${index}`"
                      :id="`check-refunsed-${index}`"
                      :key="sub.value"
                      v-model="selectEventRefunsed[index]"
                      @change="subTriggerChanged(sub.value, 'saleRefused')"
                    >
                      <p class="info-checkbox">{{ sub.text }}</p>
                    </b-form-checkbox>
                  </div>
                  <div
                    v-show="op.value == 'saleWaitingPayment' && selectEvent[5]"
                    data-anima="top"
                    class="mt-3 mb-3"
                  >
                    <b-form-checkbox
                      size="md"
                      switch
                      v-for="(sub, index) in events.options_salesWaiting"
                      class="check-box mb-2"
                      :name="`check-waiting-${index}`"
                      :id="`check-waiting-${index}`"
                      :key="sub.value"
                      v-model="selectEventWaiting[index]"
                      @change="
                        subTriggerChanged(sub.value, 'saleWaitingPayment')
                      "
                    >
                      <p class="info-checkbox">{{ sub.text }}</p>
                    </b-form-checkbox>
                  </div>
                </b-form-checkbox>
              </b-form-group>

              <b-form-group label="Assinatura" label-for="check">
                <b-form-checkbox
                  size="md"
                  switch
                  class="check-box mb-2"
                  name="check"
                  v-for="op in events.options_sub"
                  :key="op.value"
                  @change="triggerChanged(op.value)"
                >
                  <p class="info-checkbox">{{ op.text }}</p>
                </b-form-checkbox>
              </b-form-group>

              <b-form-group label="Checkout" label-for="check">
                <b-form-checkbox
                  size="md"
                  switch
                  class="check-box mb-2"
                  name="check"
                  v-for="op in events.options_checkout"
                  :key="op.value"
                  @change="triggerChanged(op.value)"
                >
                  <p class="info-checkbox">{{ op.text }}</p>
                </b-form-checkbox>
              </b-form-group>
            </div>

            <b-form-group label="Enviar para a URL" label-for="url_webhook">
              <b-form-input
                v-validate="{ url: { require_protocol: true } }"
                name="url_webhook"
                id="url_webhook"
                placeholder="https://plataforma.com/api"
                type="text"
                v-model="events.url"
                autocomplete="off"
              />
              <b-form-invalid-feedback :state="!errors.has('url_webhook')">
                Entre com uma URL válida
              </b-form-invalid-feedback>
            </b-form-group>
          </b-form>
        </div>
      </div>
    </section>

    <template v-slot:footer="{ cancel }">
      <BaseButton
        :disabled="loading"
        variant="link-info"
        class="mr-4"
        @click="cancel"
      >
        Cancelar
      </BaseButton>
      <BaseButton variant="info3" :disabled="loading" @click="adicionarWebhook">
        Criar Liberação
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
// services:
import CallbackService from "@/services/resources/CallbackService";
const serviceCallback = CallbackService.build();

export default {
  data() {
    return {
      loading: false,
      submit: false,
      webhook: {
        user_id: "",
        product_id: "",
        type: "webhook",
        object: [],
      },
      selectEvent: [],
      selectEventRefunsed: [],
      selectEventWaiting: [],
      init_click: false,
      events: {
        trigger: [],
        sub_trigger: [],
        sub_trigger2: [],
        url: "",
        callback: "executarWebhook",
        options_sales: [
          {
            value: "salePaid",
            text: "Venda Paga",
          },
          {
            value: "saleRefused",
            text: "Venda Recusada",
          },
          {
            value: "saleRefunded",
            text: "Venda Reembolsada",
          },
          {
            value: "saleChargedback",
            text: "Chargeback realizado",
          },
          {
            value: "saleUnpaid",
            text: "Venda não paga",
          },
          {
            value: "saleWaitingPayment",
            text: "Venda aguardando pagamento",
          },
        ],
        options_salesWaiting: [
          {
            value: "TODOS",
            text: "Todos",
          },
          {
            value: "BOLETO",
            text: "Boleto",
          },
          {
            value: "PIX",
            text: "PIX",
          },
        ],
        options_salesRefused: [
          {
            value: "TODOS",
            text: "Todos",
          },
          {
            value: "INVALID_CREDIT_CARD",
            text: "Cartão inválido/bloqueado",
          },
          {
            value: "INSUFFICIENT_FUNDS",
            text: "Cartão sem limite",
          },
          {
            value: "INVALID_PAYMENT_TYPE",
            text: "Método de pagamento inválido",
          },

          {
            value: "INVALID_DATA",
            text: "Dados do cartão inválidos",
          },
          {
            value: "INVALID_CLIENT_DATA",
            text: "Dados do cliente inválidos",
          },
          {
            value: "INVALID_CVV",
            text: "CVV do cartão inválido",
          },
          {
            value: "BANK",
            text: "Problema: Banco emissor do cartão",
          },
          {
            value: "CREDIT_CARD_OPERATOR",
            text: "Problema: Operadora do cartão",
          },

          {
            value: "INVALID_INSTALLMENTS",
            text: "Qtd parcelas não autorizada",
          },
          {
            value: "CURRENCY_NOT_SUPPORTED",
            text: "Moeda não autorizada",
          },
          {
            value: "SUSPECTED_FRAUD",
            text: "Suspeita de Fraude",
          },
          {
            value: "GENERIC",
            text: "Erro ao processar a compra",
          },
        ],
        options_sub: [
          {
            value: "contractUnpaid",
            text: "Assinatura não paga",
          },
          {
            value: "contractPaid",
            text: "Assinatura paga",
          },
          {
            value: "contractTrialing",
            text: "Assinatura em Trial",
          },
          {
            value: "contractPendingPayment",
            text: "Assinatura aguardando pagamento",
          },
          {
            value: "contractEnded",
            text: "Assinatura Finalizada",
          },
          {
            value: "contractCanceled",
            text: "Assinatura Cancelada",
          },
        ],
        options_checkout: [
          {
            value: "checkoutAbandoned",
            text: "Checkout Abandonado",
          },
        ],
      },
    };
  },
  props: {
    id_product: {
      type: Number,
    },
  },

  methods: {
    triggerChanged(v) {
      const include = this.events.trigger.includes(v);

      if (v == "saleRefused") {
        if (!include) {
          document.querySelector(`#check-refunsed-0`).click();
        } else {
          this.events.options_salesRefused.forEach((_, index) => {
            if (this.selectEventRefunsed[index]) {
              document.querySelector(`#check-refunsed-${index}`).click();
            }
          });
        }
      } else if (v == "saleWaitingPayment") {
        if (!include) {
          document.querySelector(`#check-waiting-0`).click();
        } else {
          this.events.options_salesWaiting.forEach((_, index) => {
            if (this.selectEventWaiting[index]) {
              document.querySelector(`#check-waiting-${index}`).click();
            }
          });
        }
      }

      if (include) {
        this.events.trigger.splice(this.events.trigger.indexOf(v), 1);
      } else {
        this.events.trigger.push(v);
      }
    },
    subTriggerChanged(v, type) {
      if (type === "saleRefused") {
        const include = this.events.sub_trigger.includes(v);

        if (v == "TODOS") {
          if (!include) {
            this.selectEventRefunsed = [];
            this.events.options_salesRefused.forEach((_, index) => {
              if (index > 0) {
                this.init_click = true;
                if (!this.selectEventRefunsed[index]) {
                  document.querySelector(`#check-refunsed-${index}`).click();
                }
              }
            });
          } else {
            this.events.options_salesRefused.forEach((_, index) => {
              if (index > 0) {
                if (this.selectEventRefunsed[index]) {
                  document.querySelector(`#check-refunsed-${index}`).click();
                }
              }
            });
          }
        }

        if (include) {
          this.events.sub_trigger.splice(this.events.sub_trigger.indexOf(v), 1);
        } else {
          this.events.sub_trigger.push(v);
        }
      } else {
        const include = this.events.sub_trigger2.includes(v);

        if (v == "TODOS") {
          if (!include) {
            this.selectEventWaiting = [];
            this.events.options_salesWaiting.forEach((_, index) => {
              if (index > 0) {
                this.init_click = true;
                if (!this.selectEventWaiting[index]) {
                  document.querySelector(`#check-waiting-${index}`).click();
                }
              }
            });
          } else {
            this.events.options_salesWaiting.forEach((_, index) => {
              if (index > 0) {
                if (this.selectEventWaiting[index]) {
                  document.querySelector(`#check-waiting-${index}`).click();
                }
              }
            });
          }
        }

        if (include) {
          this.events.sub_trigger2.splice(
            this.events.sub_trigger2.indexOf(v),
            1
          );
        } else {
          this.events.sub_trigger2.push(v);
        }
      }
    },
    openDocumentacaoWebHook() {
      window.open("https://gitlab.com/llgod/greenn-webhook");
    },
    resetWebHook() {
      this.webhook = {
        user_id: "",
        product_id: "",
        type: "webhook",
        object: [],
      };
      this.events = {
        trigger: [],
        sub_trigger: [],
        sub_trigger2: [],
        url: "",
        callback: "executarWebhook",
        options_salesWaiting: [
          {
            value: "TODOS",
            text: "Todos",
          },
          {
            value: "BOLETO",
            text: "Boleto",
          },
          {
            value: "PIX",
            text: "PIX",
          },
        ],
        options_salesRefused: [
          {
            value: "TODOS",
            text: "Todos",
          },
          {
            value: "INVALID_CREDIT_CARD",
            text: "Cartão inválido/bloqueado",
          },
          {
            value: "INSUFFICIENT_FUNDS",
            text: "Cartão sem limite",
          },
          {
            value: "INVALID_PAYMENT_TYPE",
            text: "Método de pagamento inválido",
          },

          {
            value: "INVALID_DATA",
            text: "Dados do cartão inválidos",
          },
          {
            value: "INVALID_CLIENT_DATA",
            text: "Dados do cliente inválidos",
          },
          {
            value: "INVALID_CVV",
            text: "CVV do cartão inválido",
          },
          {
            value: "BANK",
            text: "Problema: Banco emissor do cartão",
          },
          {
            value: "CREDIT_CARD_OPERATOR",
            text: "Problema: Operadora do cartão",
          },

          {
            value: "INVALID_INSTALLMENTS",
            text: "Qtd parcelas não autorizada",
          },
          {
            value: "CURRENCY_NOT_SUPPORTED",
            text: "Moeda não autorizada",
          },
          {
            value: "SUSPECTED_FRAUD",
            text: "Suspeita de Fraude",
          },
          {
            value: "GENERIC",
            text: "Erro ao processar a compra",
          },
        ],
        options_sales: [
          {
            value: "salePaid",
            text: "Venda Paga",
          },
          {
            value: "saleRefused",
            text: "Venda Recusada",
          },
          {
            value: "saleRefunded",
            text: "Venda Reembolsada",
          },
          {
            value: "saleChargedback",
            text: "Chargeback realizado",
          },
          {
            value: "saleUnpaid",
            text: "Venda não paga",
          },
          {
            value: "saleWaitingPayment",
            text: "Venda aguardando pagamento",
          },
        ],
        options_sub: [
          {
            value: "contractUnpaid",
            text: "Assinatura não paga",
          },
          {
            value: "contractPaid",
            text: "Assinatura paga",
          },
          {
            value: "contractTrialing",
            text: "Assinatura em Trial",
          },
          {
            value: "contractPendingPayment",
            text: "Assinatura aguardando pagamento",
          },
          {
            value: "contractEnded",
            text: "Assinatura Finalizada",
          },
        ],
      };
    },
    createWebhook(webhook) {
      let data = webhook;
      serviceCallback
        .create(data)
        .then(() => {
          this.submit = false;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    adicionarWebhook() {
      this.submit = true;
      if (this.events.trigger.length && this.events.url) {
        this.$validator.validate("url_webhook").then((result) => {
          if (result) {
            this.loading = true;
            document.querySelector("#url_webhook").classList.remove("erro");
            this.webhook.product_id = this.id_product;
            [...this.events.trigger].forEach((item) => {
              if (item == "saleRefused") {
                if (this.events.sub_trigger.includes("TODOS")) {
                  this.webhook.object.push({
                    product_id: this.id_product,
                    type: "webhook",
                    trigger: item,
                    callback: "executarWebhook",
                    url: this.events.url,
                  });
                }
              } else if (item == "saleWaitingPayment") {
                if (this.events.sub_trigger2.includes("TODOS")) {
                  this.webhook.object.push({
                    product_id: this.id_product,
                    type: "webhook",
                    trigger: item,
                    callback: "executarWebhook",
                    url: this.events.url,
                  });
                }
              } else {
                this.webhook.object.push({
                  product_id: this.id_product,
                  type: "webhook",
                  trigger: item,
                  callback: "executarWebhook",
                  url: this.events.url,
                });
              }
            });

            if (
              this.events.trigger.includes("saleRefused") &&
              this.events.sub_trigger.length &&
              !this.events.sub_trigger.includes("TODOS")
            ) {
              [...this.events.sub_trigger].forEach((item) => {
                if (item != "TODOS") {
                  this.webhook.object.push({
                    product_id: this.id_product,
                    type: "webhook",
                    trigger: "saleRefused",
                    sub_trigger: item,
                    callback: "executarWebhook",
                    url: this.events.url,
                  });
                }
              });
            }

            if (
              this.events.trigger.includes("saleWaitingPayment") &&
              this.events.sub_trigger2.length &&
              !this.events.sub_trigger2.includes("TODOS")
            ) {
              [...this.events.sub_trigger2].forEach((item) => {
                if (item != "TODOS") {
                  this.webhook.object.push({
                    product_id: this.id_product,
                    type: "webhook",
                    trigger: "saleWaitingPayment",
                    sub_trigger: item,
                    callback: "executarWebhook",
                    url: this.events.url,
                  });
                }
              });
            }

            this.webhook.object.forEach((item) => {
              this.createWebhook(item);
            });
            this.$bvToast.toast("Criando Webhooks", {
              title: "Webhook",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });

            // fechando modal
            this.$bvModal.hide("modal-webhook");
            //emitindo evento para o componente pai (opcoesEntrega)
            setTimeout(() => {
              this.$emit("close");
            }, 1000);
            // limpando campos
            this.resetWebHook();
            this.loading = false;
            this.submit = false;
            this.selectEvent = [];
            this.selectEventRefunsed = [];
            this.selectEventWaiting = [];
          } else {
            if (document.querySelector("#url_webhook")) {
              document.querySelector("#url_webhook").classList.add("erro");
              document.querySelector("#url_webhook").focus();
            }
            this.$bvToast.toast("Entre com uma URL válida", {
              title: "Webhook",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
          }
        });
      } else {
        this.$bvToast.toast("Todos os campos são obrigatórios. Verifique", {
          title: "Webhook",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
      }
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
.title-inter {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #2a63ab;
}
.form-group + .form-group {
  margin-top: 15px;
}
.check-box {
  /* margin-left: 40px; */
}
.check-box .info-checkbox {
  font-size: 13px;
  font-weight: 500;
  margin-left: 5px;
  cursor: pointer;
  max-width: 250px;
}
.grid-events {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 20px 0;
}
.link-documentacao {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}
</style>
