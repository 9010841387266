<template>
  <BaseModal name="modal-conexoes" size="lg" title="Gerencie suas conexões">
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <div v-else>
      <h4 class="title-inter">• Qual a Plataforma que deseja Integrar ?</h4>

      <form>
        <b-form-group label="Plataforma">
          <b-form-select
            v-model="plataform"
            :options="plataforms"
            v-validate="'required'"
            name="plataform"
          ></b-form-select>
          <b-form-invalid-feedback
            :state="submit ? !errors.has('plataform') : null"
          >
            Por favor, selecione a plataforma para esta conexão.
          </b-form-invalid-feedback>
        </b-form-group>

        <b-row>
          <b-col cols="12" md="6">
            <b-form-group label="Nome da conexão" label-for="connection_name">
              <b-form-input
                id="connection_name"
                name="connection_name"
                v-model="connection_name"
                v-validate="'required'"
                type="text"
              ></b-form-input>
              <b-form-invalid-feedback
                :state="submit ? !errors.has('connection_name') : null"
              >
                Por favor, insira um nome para esta conexão.
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group label="API Key" label-for="api_key">
              <b-form-input
                id="api_key"
                name="api_key"
                v-model="connection_api_key"
                v-validate="'required'"
                type="text"
              ></b-form-input>
              <b-form-invalid-feedback
                :state="submit ? !errors.has('api_key') : null"
              >
                Por favor, insira a API Key.
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
      </form>
    </div>

    <template v-slot:footer="{ cancel }">
      <BaseButton
        :disabled="loading"
        variant="link-info"
        class="mr-4"
        @click="cancel"
      >
        Cancelar
      </BaseButton>

      <BaseButton
        variant="secondary"
        :disabled="loading"
        @click="createIntegration"
        >Criar Integração</BaseButton
      >
    </template>
  </BaseModal>
</template>

<script>
// services:
import ConnectionService from "@/services/resources/ConnectionService";
const serviceConnection = ConnectionService.build();

export default {
  data() {
    return {
      loading: false,
      submit: false,
      plataform: null,
      connection_name: null,
      connection_api_key: null,
      plataforms: [
        { value: null, text: "Selecione uma plataforma" },
        { value: "memberkit", text: "Memberkit" },
      ],
      api_key: "",
    };
  },
  methods: {
    createIntegration() {
      this.submit = true;

      this.$validator.validateAll().then((result) => {
        if (result) {
          this.loading = true;
          let data = {
            title: this.connection_name,
            type: this.plataform,
            api_key: this.connection_api_key,
          };

          serviceConnection
            .create(data)
            .then((response) => {
              this.$bvToast.toast("Conexão salva com sucesso!", {
                title: "Conexões",
                variant: "success",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.connection_name = null;
              this.connection_api_key = null;
              this.plataform = null;
              this.$bvModal.hide("modal-conexoes");
              this.$emit("close");
            })
            .catch((err) => {
              console.log(err);
              this.$bvToast.toast("Erro ao salvar a Conexão!", {
                title: "Conexões",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .finally(() => {
              this.submit = false;
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.title-inter {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #2a63ab;
}
</style>
