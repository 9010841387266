<template>
  <b-overlay :show="submit" spinner-variant="dark">
    <div class="container">
      <BaseHeader :title="product.name">
        <BaseButton
          variant="link-info"
          @click="todosProdutos"
          class="mt-4 mt-md-0"
          >Visualizar todos os produtos</BaseButton
        >
      </BaseHeader>
      <div class="top-infos">
        <p class="mb-md-5" :class="{ 'mb-4': product.status != 'REQUESTED' }">
          Edite aqui todas as particularidades do seu produto.
        </p>
        <div class="aprovacao btn-2" v-if="product.status == 'REQUESTED'">
          <img src="@/assets/img/icons/info-prod.svg" />
          Produto em Processo Análise
        </div>
      </div>

      <PageNotFound v-if="error_get_product" :no_product="true" />

      <b-tabs
        pills
        vertical
        nav-class="TabProduct"
        active-nav-item-class="TabProduct--active"
        class="container-form"
        v-if="!error_get_product"
      >
        <b-tab title="Geral" active @click="submitOffer = true">
          <h5>Geral</h5>
          <b-form>
            <div data-anima="left" class="container-form" v-show="!loading">
              <div class="input-upload">
                <b-col cols="12" md="4">
                  <b-form-group label="" class="img-produto">
                    <vue-upload-multiple-image
                      @upload-success="uploadImageSuccess"
                      @before-remove="beforeRemove"
                      :data-images="product.images"
                      idUpload="myIdUpload"
                      editUpload="myIdEdit"
                      dragText="📷 Fotos do Produto"
                      browseText="Procurar imagens"
                      :showPrimary="false"
                      :showEdit="false"
                    ></vue-upload-multiple-image>
                  </b-form-group>
                  <div class="recomendado">
                    <p>Recomendado:</p>
                    <p>120px de largura</p>
                    <p>120px de altura</p>
                  </div>
                </b-col>
              </div>
              <div class="form-produto">
                <b-form-group label="Título do Produto" label-for="name">
                  <b-form-input
                    name="titulo_produto"
                    id="titulo_produto"
                    placeholder="Título curto, ex: Curso de Marketing 2.0"
                    v-model="product.name"
                    v-validate="'required'"
                    type="text"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    :state="!errors.has('titulo_produto')"
                  >
                    O campo título do produto é obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  class="descricao-container"
                  label="Descrição do Produto"
                  label-for="description"
                >
                  <div
                    class="min-descricao"
                    :class="{ ok: product.description.length >= 200 }"
                  >
                    {{ product.description.length }}
                  </div>
                  <b-form-textarea
                    id="description"
                    name="description"
                    v-model="product.description"
                    v-validate="'required|min:200'"
                    placeholder="Uma breve descrição do produto/serviço, benefícios e etc"
                  ></b-form-textarea>
                  <b-form-invalid-feedback :state="!errors.has('description')">
                    O campo descrição deve ter pelo menos 200 caracteres
                  </b-form-invalid-feedback>
                  <div class="mark-down mt-2">
                    <p class="info-mark">Descrição formatada em MarkDown</p>
                    <a
                      href="#"
                      class="info-mark open-mark"
                      @click.prevent="openMD('descricao')"
                      >Formatar descrição</a
                    >
                  </div>
                </b-form-group>

                <b-form-group label="Página de Vendas" label-for="sales_page">
                  <b-form-input
                    id="sales_page"
                    name="sales_page"
                    placeholder="Link página de vendas"
                    v-model="product.sales_page"
                    v-validate="'required|url'"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('sales_page')">
                    Por favor, insira a página de vendas
                  </b-form-invalid-feedback>
                  <div class="mark-down mt-2">
                    <p class="info-mark">
                      Caso não tenha uma página de vendas, informe o Instagram,
                      Facebook ou afins onde será vendido o produto para
                      conhecermos melhor o seu trabalho até que tenha página de
                      vendas.
                    </p>
                  </div>
                </b-form-group>

                <b-form-group label="Tipo do Produto" label-for="format">
                  <b-form-select
                    name="format"
                    v-model="product.format"
                    :options="formats_product"
                    @change="changeFormatProduct"
                    v-validate="'required'"
                  ></b-form-select>
                  <b-form-invalid-feedback :state="!errors.has('format')">
                    Por favor, selecione o tipo do produto
                  </b-form-invalid-feedback>
                </b-form-group>

                <div class="flex-row">
                  <b-col cols="12" md="6">
                    <b-form-group label="Categoria" label-for="category">
                      <b-form-select
                        name="category"
                        v-model="product.category_id"
                        :options="categories"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="5">
                    <b-form-group
                      label="Prazo de reembolso (dias)"
                      label-for="warranty"
                    >
                      <b-form-input
                        placeholder="30"
                        name="warranty"
                        v-model="product.warranty"
                        v-mask="'###'"
                        v-validate="'numeric|min_value:7'"
                        type="text"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </div>
              </div>
            </div>
          </b-form>
        </b-tab>
        <b-tab
          title="Produto Físico"
          v-if="product.format == 'PHYSICALPRODUCT'"
          @click="openProdutoFisico, (submitOffer = true)"
        >
          <div data-anima="left" v-if="!loading">
            <h5>Configurações do Produto Físico</h5>
            <div class="grid-row mt-4">
              <!-- estoque -->
              <b-col class="">
                <b-form-group label="Estoque" label-for="has_stock ">
                  <b-form-checkbox
                    v-model="product.has_stock"
                    name="has_stock"
                    size="lg"
                    switch
                  >
                    <p class="info-checkbox">
                      Produto tem estoque
                      <span class="descricao-checkbox"
                        >Caso habilitado, iremos controlar a quantidade de
                        vendas com base no seu estoque</span
                      >
                    </p>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>

              <b-col
                v-if="product.has_stock"
                cols="12"
                md="5"
                class="mt-3 mt-md-0"
                data-anima="top"
              >
                <b-form-group
                  label="Qtd em estoque"
                  label-for="stock"
                  data-anima="top"
                >
                  <b-form-input
                    id="stock"
                    placeholder="30"
                    name="stock"
                    v-model="product.stock"
                    v-validate="'required|numeric|min_value:1'"
                    type="number"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('stock')">
                    Mínimo 1 produto em estoque.
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </div>
            <div class="grid-row mt-4">
              <!-- presente -->
              <b-col class="">
                <b-form-group label="Presente 🎁" label-for="can_be_gifted">
                  <b-form-checkbox
                    v-model="product.can_be_gifted"
                    name="can_be_gifted"
                    size="lg"
                    switch
                  >
                    <p class="info-checkbox">
                      Produto pode ser presenteado
                      <span class="descricao-checkbox"
                        >Comprador pode informar uma mensagem personalizada no
                        momento da compra</span
                      >
                    </p>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </div>

            <!-- removendo personalização do frete -->
            <div class="grid-row mt-4">
              <!-- frete -->
              <b-col cols="12">
                <b-form-group label="Frete" label-for="has_shipping_fee">
                  <!-- v-if="true == false" -->
                  <b-form-checkbox
                    v-model="product.has_shipping_fee"
                    name="has_shipping_fee"
                    size="lg"
                    switch
                    @change="changeFrete"
                  >
                    <!-- disabled -->
                    <p class="info-checkbox">
                      Produto terá frete
                      <span class="descricao-checkbox"
                        >O frete será cobrado do comprador de forma
                        separada</span
                      >
                    </p>
                  </b-form-checkbox>
                </b-form-group>
                <div>
                  <div class="mt-4">
                    <b-form-group
                      label="Endereço de expedição"
                      label-for="has_custom_shipping_address"
                      data-anima="top"
                    >
                      <!-- v-if="product.has_shipping_fee" -->
                      <b-form-checkbox
                        v-model="product.has_custom_shipping_address"
                        name="has_custom_shipping_address"
                        size="lg"
                        switch
                      >
                        <p class="info-checkbox">
                          Endereço de expedição personalizado
                          <span class="descricao-checkbox"
                            >Expedição do produto será feita de um endereço
                            diferente</span
                          >
                        </p>
                      </b-form-checkbox>
                    </b-form-group>

                    <b-row
                      class="mt-4"
                      v-if="
                        product.has_custom_shipping_address && !loading_address
                      "
                      data-anima="top"
                    >
                      <b-col cols="12">
                        <b-form-group
                          label="Selecione o endereço"
                          label-for="address_product_id"
                        >
                          <b-form-select
                            id="address_product_id"
                            name="address_product_id"
                            v-validate="'required'"
                            v-model="product.address_product_id"
                            :options="enderecos_select"
                          ></b-form-select>
                          <b-form-invalid-feedback
                            :state="!errors.has('address_product_id')"
                          >
                            Selecione o endereço
                          </b-form-invalid-feedback>
                        </b-form-group>
                        <div id="endereco">
                          <a
                            href="#"
                            @click.prevent="openAddress"
                            class="info-mark open-mark"
                            >Adicionar novo endreço</a
                          >
                        </div>
                      </b-col>
                    </b-row>
                    <div v-if="loading_address">
                      <b-spinner label="Loading..."></b-spinner>
                    </div>
                  </div>
                </div>
              </b-col>
              <b-col cols="12" class="mt-4 mt-md-0" data-anima="top">
                <!-- v-if="product.has_shipping_fee" -->
                <b-row class="ml-md-5 ml-0">
                  <b-col cols="12" md="6">
                    <b-form-group
                      label="Dimensões do produto"
                      label-for="product_weight"
                    >
                      <b-form-input
                        id="product_weight"
                        name="product_weight"
                        placeholder="Peso (kg)"
                        v-model="product.weight"
                        v-validate="'required|decimal|min_value:0'"
                        type="number"
                        step=".1"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        :state="!errors.has('product_weight')"
                      >
                        Digite o Peso (kg).
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group label="" label-for="product_height">
                      <b-form-input
                        type="number"
                        id="product_height"
                        name="product_height"
                        placeholder="Altura (cm)"
                        v-model="product.height"
                        v-validate="'required|decimal|min_value:0'"
                        step=".1"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        :state="!errors.has('product_height')"
                      >
                        Digite a Altura (cm).
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group label="" label-for="product_width">
                      <b-form-input
                        type="number"
                        id="product_width"
                        name="product_width"
                        placeholder="Largura (cm)"
                        v-model="product.width"
                        v-validate="'required|decimal|min_value:0'"
                        step=".1"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        :state="!errors.has('product_width')"
                      >
                        Digite a Largura (cm).
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group label="" label-for="product_length">
                      <b-form-input
                        type="number"
                        id="product_length"
                        name="product_length"
                        placeholder="Comprimento"
                        v-model="product.length"
                        v-validate="'required|decimal|min_value:0'"
                        step=".1"
                      ></b-form-input>
                      <b-form-invalid-feedback
                        :state="!errors.has('product_length')"
                      >
                        Digite o Comprimento.
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </div>
          </div>
        </b-tab>
        <b-tab title="Cobrança" @click="initCobranca">
          <div data-anima="left" v-if="!loading">
            <!-- <h5>Cobrança</h5> -->
            <!-- <div class="d-block mb-2 d-md-flex">
              <b-col cols="12" md="5">
                <label>
                  Tipo de Cobrança: {{  }}
                </label>
              </b-col>
            </div> -->

            <Cobranca
              @changeValue="changeValue"
              :isMobile="isMobile"
              :co_sellers_list="co_sellers_list"
              :id_product="id_product"
              :warranty="product.warranty"
              :methods="methods"
              :option_fixed_installments="option_fixed_installments"
              :option_max_installments="option_max_installments"
              :product="product"
            />
            <!-- <div class="d-block d-md-flex mt-4 mb-3" v-if="submitOffer">
              <b-col cols="12" md="5">
                <b-form-group label="Propostas" label-for="allow_proposal">
                  <b-form-checkbox
                    v-model="product.allow_proposal"
                    name="allow_proposal "
                    size="lg"
                    switch
                  >
                    <p class="info-checkbox">
                      Permitir propostas
                      <span class="descricao-checkbox"
                        >Permitindo a proposta será possível renegociar o
                        valor do produto</span
                      >
                    </p>
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group
                  label="Desconto (%)"
                  label-for="proposal_minimum"
                  v-if="product.allow_proposal"
                  data-anima="top"
                >
                  <b-form-input
                    placeholder="15%"
                    name="proposal_minimum"
                    v-model="product.proposal_minimum"
                    type="number"
                    v-validate="'required|numeric|max_value:99'"
                  ></b-form-input>
                </b-form-group>
                <b-form-invalid-feedback
                  :state="!errors.has('proposal_minimum')"
                >
                  Desconto máximo é 99%
                </b-form-invalid-feedback>
              </b-col>

              <b-col
                cols="12"
                md="5"
                class="mt-4 mt-md-0"
                v-if="product.allow_proposal"
                data-anima="top"
              >
                <b-form-group label="Afiliados" label-for="allow_proposal">
                  <b-form-checkbox
                    v-model="product.affiliation_proposal"
                    name="affiliation_proposal"
                    size="lg"
                    switch
                  >
                    <p class="info-checkbox">
                      Propostas de Afiliados
                      <span class="descricao-checkbox"
                        >Afiliados poderão renegocioar o valor do produto com
                        o vendedor</span
                      >
                    </p>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </div> -->
            <!-- <div>
              <div class="d-block d-md-flex">
                <b-col cols="12" md="5">
                  <b-form-group label="Tipo de Cobrança" label-for="type">
                    <b-form-select
                      name="type"
                      v-model="product.type"
                      :options="types"
                      @change="changeTypes"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="3" class="ml-md-3 preco">
                  <b-form-group label="Preço" label-for="amount">
                    <money
                      id="amount"
                      v-model="product.amount"
                      v-bind="money"
                    ></money>
                  </b-form-group>
                </b-col>
              </div>
              <div
                class="d-block d-md-flex mt-2"
                v-if="product.type == 'SUBSCRIPTION'"
              >
                <b-col cols="12" md="5" data-anima="top">
                  <b-form-group
                    label="Limite de cobranças (opcional)"
                    label-for="charges"
                  >
                    <b-form-input
                      name="charges"
                      v-model="product.charges"
                      type="number"
                      placeholder="Ex: 15 "
                      v-validate="'alpha_num'"
                    ></b-form-input>

                    <div class="mark-down mt-2" data-anima="top">
                      <p
                        class="info-mark"
                        v-if="product.charges && product.charges > 0"
                      >
                        Essa assinatura será cobrada {{ product.charges }}x e
                        depois será encerrada automaticamente
                      </p>
                      <p class="info-mark" v-else>
                        Assinatura sem limite de cobranças
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
              </div>
              <div
                class="d-block d-md-flex mt-3"
                v-if="product.type == 'SUBSCRIPTION'"
              >
                <b-col cols="12" md="5" data-anima="top">
                  <b-form-group label="Período" label-for="period">
                    <b-form-select
                      name="period"
                      v-model="product.period"
                      v-validate="'alpha_num'"
                      :options="periods"
                      @change="changeParcelasSub"
                    ></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col
                  class="ml-md-3"
                  v-if="
                    product.type == 'SUBSCRIPTION' && product.period == 'CUSTOM'
                  "
                  data-anima="top"
                >
                  <b-form-group
                    label="Novo período (em dias)"
                    label-for="custom_period"
                  >
                    <b-form-input
                      name="custom_period"
                      v-model="custom_period"
                      type="number"
                      placeholder="Período em dias. Ex: 730 "
                      v-validate="'required|alpha_num'"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      :state="!errors.has('custom_period')"
                    >
                      Por favor, digite o período personalizado
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                  v-if="product.type == 'SUBSCRIPTION'"
                  class="ml-md-3 mt-3 mt-md-0"
                  data-anima="top"
                >
                  <b-form-group label="Avaliação (opcional)" label-for="trial">
                    <b-form-input
                      name="trial"
                      v-model="product.trial"
                      type="text"
                      placeholder="Período de Avaliação"
                      v-validate="'alpha_num'"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </div>
              <div class="flex-row mt-3">
                <b-col cols="12" md="8">
                  <b-form-group label="Métodos de pagamento" label-for="method">
                    <multiselect
                      v-model="product.method"
                      :options="methods"
                      :value="product.method"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :preserve-search="true"
                      placeholder="Método de Pagagamento"
                      label="name"
                      track-by="name"
                      selectLabel=""
                      deselectLabel=""
                      selectedLabel="✔️"
                      class="multi-select-input"
                      id="multi_select_metodo"
                    >
                    </multiselect>
                  </b-form-group>
                </b-col>
              </div>
              <div
                class="d-block d-md-flex mt-4"
                v-if="!(product.type == 'SUBSCRIPTION' && product.period == 30)"
              >
                <b-col cols="12" md="5" data-anima="top">
                  <b-form-group
                    label="Parcela Fixa"
                    label-for="fixed_installments"
                  >
                    <b-form-select
                      id="fixed_installments"
                      name="fixed_installments"
                      v-model="product.fixed_installments"
                      :options="option_fixed_installments"
                      @change="changeFixedInstallments()"
                    ></b-form-select>
                    <div class="mark-down" v-if="product.fixed_installments">
                      <p class="info-mark">
                        Cliente poderá parcelar o produto apenas em
                        {{ product.fixed_installments }}x
                      </p>
                    </div>
                  </b-form-group>
                </b-col>
              </div>
              <div
                class="d-block d-md-flex mt-2"
                v-if="!product.fixed_installments"
              >
                <b-col
                  cols="12"
                  md="5"
                  v-if="
                    product.type == 'SUBSCRIPTION' &&
                      (product.period > 30 || product.period == 'CUSTOM')
                  "
                  data-anima="top"
                >
                  <b-form-group
                    class="mr-3"
                    label="Parcelas da Assinatura"
                    label-for="subscription_installments"
                  >
                    <b-form-select
                      id="subscription_installments"
                      name="subscription_installments"
                      v-model="product.max_subscription_installments"
                      :options="subscription_installments"
                      v-validate="'required'"
                      @change="customPreInstallmentsOptionsSubscriptionAss()"
                    ></b-form-select>
                    <div class="mark-down mb-3">
                      <p class="info-mark">
                        Apenas para pagamentos com Cartão de Crédito
                      </p>
                    </div>

                    <b-form-invalid-feedback
                      :state="!errors.has('subscription_installments')"
                    >
                      Por favor, selecione as parcelas.
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="5" data-anima="top"
                  v-if="product.type === 'SUBSCRIPTION' && product.period !== 30 ? true : false"
                >
                  <b-form-group
                    id="tooltip_installment"
                    label="Parcela pré-selecionada"
                    label-for="pre_selected_installment"
                  >
                    <b-form-select
                      id="pre_selected_installment"
                      name="pre_selected_installment"
                      v-model="product.pre_selected_installment"
                      :options="option_pre_selected_installment"
                    ></b-form-select>
                    <div class="mark-down" v-if="product.pre_selected_installment">
                      <p class="info-mark">
                        Ao entrar no checkout do produto, a parcela pré-selecionada será
                        {{ product.pre_selected_installment }}x
                      </p>
                    </div>
                  </b-form-group>
                  <b-tooltip target="tooltip_installment" triggers="hover">
                    Parcela ficará pré-selecionada no checkout
                  </b-tooltip>
                </b-col>
              </div>
              <div
                class="d-block d-md-flex mt-2"
                v-if="!product.fixed_installments"
              >
                <b-col
                  cols="12"
                  md="5"
                  v-if="product.type == 'TRANSACTION'"
                  data-anima="top"
                >
                  <b-form-group
                    class="mr-3"
                    label="Parcelas Cartão de Crédito"
                    label-for="max_installments"
                  >
                    <b-form-select
                      id="max_installments"
                      name="max_installments"
                      v-model="product.max_installments"
                      :options="option_max_installments"
                      v-validate="'required'"
                      @change="customPreInstallmentsOptions()"
                    ></b-form-select>
                    <b-form-invalid-feedback
                      :state="!errors.has('max_installments')"
                    >
                      Por favor, selecione as parcelas.
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="5" data-anima="top"
                  v-if="product.type !== 'SUBSCRIPTION' ? true : false"
                >
                  <b-form-group
                    id="tooltip_installment"
                    label="Parcela pré-selecionada"
                    label-for="pre_selected_installment"
                  >
                    <b-form-select
                      id="pre_selected_installment"
                      name="pre_selected_installment"
                      v-model="product.pre_selected_installment"
                      :options="option_pre_selected_installment"
                    ></b-form-select>
                    <div class="mark-down" v-if="product.pre_selected_installment">
                      <p class="info-mark">
                        Ao entrar no checkout do produto, a parcela pré-selecionada será
                        {{ product.pre_selected_installment }}x
                      </p>
                    </div>
                  </b-form-group>
                  <b-tooltip target="tooltip_installment" triggers="hover">
                    Parcela ficará pré-selecionada no checkout
                  </b-tooltip>
                </b-col>
              </div>

              <div
                class="d-block d-md-flex mt-4 mb-3"
                v-if="product.type == 'TRANSACTION'"
                data-anima="top"
              >
                <b-col
                  cols="12"
                  md="5"
                  v-if="product.format !== 'PHYSICALPRODUCT'"
                >
                  <b-form-group
                    label="Parcelamento de Boleto"
                    label-for="permite_parcela_boleto "
                  >
                    <b-form-checkbox
                      v-model="permite_parcela_boleto"
                      name="permite_parcela_boleto  "
                      size="lg"
                      switch
                      @change="changeJurosBoleto"
                    >
                      <p class="info-checkbox">
                        Permitir parcelamento de boleto
                        <span class="descricao-checkbox"
                          >Clientes terão a opção de parcelar a compra por
                          boletos bancário.</span
                        >
                      </p>
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="2">
                  <b-form-group
                    label="Qtd de parcelas"
                    label-for="max_boleto_installments"
                    v-if="permite_parcela_boleto"
                    data-anima="top"
                  >
                    <b-form-input
                      placeholder="2"
                      id="parcelas_boleto"
                      name="max_boleto_installments"
                      v-model="product.max_boleto_installments"
                      max="12"
                      min="1"
                      type="number"
                      v-validate="'required|numeric|min_value:2|max_value:12'"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-invalid-feedback
                    :state="!errors.has('max_boleto_installments')"
                  >
                    Mínimo 2x, máximo 12x
                  </b-form-invalid-feedback>
                </b-col>
              </div>

              <div class="d-block mt-4 mb-3">
                <b-col cols="12" md="5">
                  <b-form-group
                    label="Cupom de Desconto"
                    label-for="allowed_coupon "
                  >
                    <b-form-checkbox
                      v-model="product.allowed_coupon"
                      name="allowed_coupon"
                      size="lg"
                      switch
                    >
                      <p class="info-checkbox">
                        Permitir Cupom de Desconto
                        <span class="descricao-checkbox"
                          >Os clientes poderão adicionar cupom de desconto na
                          compra do produto</span
                        >
                      </p>
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </div>
              <div class="d-block mt-4 mb-3">
                <b-col cols="12" md="5">
                  <b-form-group
                    label="Juros de parcelamento"
                    label-for="no_interest_installments "
                  >
                    <b-form-checkbox
                      v-model="product.no_interest_installments"
                      name="no_interest_installments"
                      size="lg"
                      switch
                      @change="changeJurosBoleto"
                    >
                      <p class="info-checkbox">
                        Vendedor assume o juros (apenas cartão de crédito)
                        <span class="descricao-checkbox"
                          >Juros não será cobrado do cliente no momento da
                          compra. Será descontado do vendedor
                        </span>
                      </p>
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </div>
              <div class="d-block d-md-flex mt-4 mb-3">
                <b-col cols="12" md="5">
                  <b-form-group label="Propostas" label-for="allow_proposal">
                    <b-form-checkbox
                      v-model="product.allow_proposal"
                      name="allow_proposal "
                      size="lg"
                      switch
                    >
                      <p class="info-checkbox">
                        Permitir propostas
                        <span class="descricao-checkbox"
                          >Permitindo a proposta será possível renegociar o
                          valor do produto</span
                        >
                      </p>
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="5"
                  class="mt-4 mt-md-0"
                  v-if="product.allow_proposal"
                  data-anima="top"
                >
                  <b-form-group label="Afiliados" label-for="allow_proposal">
                    <b-form-checkbox
                      v-model="product.affiliation_proposal"
                      name="affiliation_proposal"
                      size="lg"
                      switch
                    >
                      <p class="info-checkbox">
                        Propostas de Afiliados
                        <span class="descricao-checkbox"
                          >Afiliados poderão renegocioar o valor do produto com
                          o vendedor</span
                        >
                      </p>
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </div>

              <b-col cols="12" md="3">
                <b-form-group
                  label="Desconto (%)"
                  label-for="proposal_minimum"
                  v-if="product.allow_proposal"
                  data-anima="top"
                >
                  <b-form-input
                    placeholder="15%"
                    name="proposal_minimum"
                    v-model="product.proposal_minimum"
                    type="number"
                    v-validate="'required|numeric|max_value:99'"
                  ></b-form-input>
                </b-form-group>
                <b-form-invalid-feedback
                  :state="!errors.has('proposal_minimum')"
                >
                  Desconto máximo é 99%
                </b-form-invalid-feedback>
              </b-col>
              <div></div>
            </div> -->
          </div>
        </b-tab>
        <b-tab title="Parceiros" @click="submitOffer = true">
          <div data-anima="left" v-if="!loading">
            <div class="container-form container-integracoes">
              <div class="lista-entrega">
                <h5>Co-produtores</h5>
                <!-- Componentes lista Co-Seller -->
                <ListagemCoseller :lista="co_sellers_list" />
              </div>
              <div class="nova-entrega">
                <BaseButton
                  variant="secondary_outline"
                  @click="openModal('product-co-sellers')"
                  >Adicionar Coprodutor</BaseButton
                >
                <!-- v-b-modal.product-co-sellers -->
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab title="Afiliados" @click="submitOffer = true">
          <div data-anima="left" v-if="!loading">
            <h5>Afiliados</h5>

            <div class="grid-row mt-4">
              <b-col class="">
                <b-form-group label="" label-for="affiliation">
                  <b-form-checkbox
                    v-model="product.affiliation"
                    name="affiliation"
                    size="lg"
                    switch
                    class="checkbox-cursor"
                  >
                    <p class="info-checkbox">
                      Permitir Afiliação
                      <span class="descricao-checkbox"
                        >Poderão se afiliar ao seu produto e te ajudar a vender
                        mais.</span
                      >
                    </p>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>

              <b-col
                v-if="product.affiliation"
                cols="12"
                md="5"
                class="mt-3 mt-md-0"
                data-anima="top"
              >
                <b-form-group label="Comissão %" label-for="comission">
                  <b-form-input
                    placeholder="15%"
                    name="comission"
                    id="comissao"
                    v-model="product.comission"
                    type="number"
                    v-validate="'required|numeric|min_value:1|max_value:99'"
                  ></b-form-input>
                </b-form-group>
                <b-form-invalid-feedback :state="!errors.has('comission')">
                  Comissão min 1% e max 99%
                </b-form-invalid-feedback>
              </b-col>
            </div>
            <div class="mt-4">
              <div class="grid-row">
                <b-col
                  v-if="product.affiliation"
                  class="mb-4 mb-md-0"
                  data-anima="top"
                >
                  <b-form-group label="" label-for="affiliation_approbation">
                    <b-form-checkbox
                      v-model="product.affiliation_approbation"
                      name="affiliation_approbation"
                      size="lg"
                      switch
                      class="checkbox-cursor"
                    >
                      <p class="info-checkbox">
                        Quero aprovar afiliações
                        <span class="descricao-checkbox"
                          >Solicitações de afiliação serão enviadas à você para
                          aprovação.</span
                        >
                      </p>
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>

                <!-- Vitrine -->
                <b-col v-if="product.affiliation" class="" data-anima="top">
                  <b-form-group label="" label-for="affiliation_public">
                    <b-form-checkbox
                      v-model="product.affiliation_public"
                      name="affiliation_public"
                      size="lg"
                      switch
                      class="checkbox-cursor"
                    >
                      <p class="info-checkbox">
                        Disponível na Vitrine
                        <span class="descricao-checkbox"
                          >Produto ficará disponível na vitrine de
                          afiliação</span
                        >
                      </p>
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </div>
              <div class="d-block d-md-flex mt-4" v-if="product.affiliation">
                <b-row>
                  <b-col cols="12" class="mb-4">
                    <b-form-group
                      class="descricao-container"
                      label="Instruções para o Afiliado"
                      label-for="instructions"
                    >
                      <b-form-textarea
                        id="instructions"
                        name="instructions"
                        v-model="product.instructions"
                        placeholder="Escreva aqui as instruções para os afiliados do seu produto"
                      ></b-form-textarea>
                      <div class="mark-down mt-2">
                        <p class="info-mark mr-1">
                          Instruções formatada em MarkDown
                        </p>
                        <a
                          href="#"
                          class="info-mark open-mark"
                          @click.prevent="openMD('instrucoes')"
                          >Formatar instruções</a
                        >
                      </div>
                    </b-form-group>
                  </b-col>

                  <b-col
                    cols="12"
                    v-if="product.type == 'SUBSCRIPTION'"
                    data-anima="top"
                  >
                    <b-form-group
                      label="Receberá comissão até"
                      label-for="max_installments"
                    >
                      <b-form-select
                        id="max_comission"
                        name="max_comission"
                        v-model="product.max_comission"
                        :options="option_max_comission"
                        v-validate="'required'"
                      ></b-form-select>
                      <div class="mark-down mb-3">
                        <p class="info-mark">
                          Afiliado receberá comissão até a parcela selecionada
                        </p>
                      </div>
                      <b-form-invalid-feedback
                        :state="!errors.has('max_comission')"
                      >
                        Por favor, selecione as parcelas.
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab
          title="Entregas"
          @click="initIntegracoes(), (submitOffer = true)"
        >
          <div
            class="container-form container-integracoes"
            data-anima="left"
            v-if="!loading"
          >
            <div class="lista-entrega">
              <h5>Listagem de Entregas</h5>
              <ListagemArquivos
                :id_product="+id_product"
                :lista="files_list"
                v-if="tabs.files"
              />

              <ListagemMemberkit
                :id_product="+id_product"
                v-if="tabs.memberkit"
              />

              <ListagemActive
                :id_product="+id_product"
                v-if="tabs.activecampaing"
              />

              <ListagemSheets :id_product="+id_product" v-if="tabs.sheets" />

              <div class="mt-4">
                <ListagemWebhook
                  :id_product="+id_product"
                  v-if="tabs.webhook"
                />
              </div>

              <div class="mt-4">
                <ListagemInfusionsoft
                  :id_product="+id_product"
                  v-if="tabs.infusionsoft"
                />
              </div>

              <div class="mt-4">
                <ListagemVoxuy :id_product="+id_product" v-if="tabs.voxuy" />
              </div>

              <div class="mt-4">
                <ListagemLeadLovers
                  :id_product="+id_product"
                  v-if="tabs.leadlovers"
                />
              </div>

              <b-row key="nao-encontrado" v-if="!loading && !existe_integracao">
                <p class="nao-encontrado">
                  Adicione as entregas de conteúdo do seu produto por aqui,
                  sendo documentos, integrações com áreas de membros externas ou
                  webhook.
                </p>
              </b-row>
            </div>
            <div class="nova-entrega">
              <BaseButton
                variant="secondary_outline"
                class="btn-entrega"
                @click="openModal('entregas-opcoes')"
                >Adicionar Entregas</BaseButton
              >
            </div>
          </div>
        </b-tab>
        <b-tab
          title="GreennClub"
          @click="initClub(), (submitOffer = true)"
          class="tab-logo"
        >
          <template #title>
            Voomp Play
            <!-- <img src="@/assets/img/logo-club.png" class="logo-tab" /> -->
          </template>
          <div
            class="container-form container-integracoes"
            data-anima="left"
            v-if="!loading"
          >
            <div class="lista-entrega">
              <h5>Listagem Voomp Play</h5>
              <ListagemClub :id_product="+id_product" v-if="tabs.club" />
              <b-row key="nao-encontrado" v-else>
                <p class="nao-encontrado">
                  Uma Plataforma de Membros com experiência WOW para você e seus
                  Alunos! Ofereça os seus cursos com uma ferramenta criada
                  especialmente para ajudar quem realmente tem sede de aprender.
                </p>
              </b-row>
            </div>
            <div class="nova-entrega">
              <BaseButton
                v-if="clubIntegration"
                variant="secondary_outline"
                class="btn-entrega button-info3 btn-club mb-2"
                @click="loginClub()"
                >Entrar no Voomp Play</BaseButton
              >
              <BaseButton
                variant="secondary_outline"
                class="btn-entrega"
                @click="openModal('modal-integracoes_club')"
                >Adicionar Voomp Play</BaseButton
              >
            </div>
          </div>
        </b-tab>
        <b-tab
          title="VoompEnvios"
          @click="initEnvios(), (submitOffer = true)"
          class="tab-logo"
        >
          <template #title>
            <!-- <img
              style="margin-left: -7px"
              src="@/assets/img/logo-envios.svg"
              class="logo-tab"
            /> -->
            Voomp Envios
          </template>
          <div
            class="container-form container-integracoes"
            data-anima="left"
            v-if="!loading"
          >
            <div class="lista-entrega">
              <h5>Listagem VoompEnvios</h5>
              <ListagemEnvios :id_product="+id_product" v-if="tabs.envios" />
              <b-row key="nao-encontrado" v-else>
                <p class="nao-encontrado">
                  Nenhuma integração com VoompEnvios
                </p>
              </b-row>
            </div>
            <div class="nova-entrega">
              <BaseButton
                variant="secondary_outline"
                class="btn-entrega button-info3 btn-club mb-2"
                @click="loginEnvios()"
                >Entrar no VoompEnvios</BaseButton
              >
              <BaseButton
                variant="secondary_outline"
                class="btn-entrega"
                @click="openModal('modal-integracoes_envios')"
                >Adicionar VoompEnvios</BaseButton
              >
            </div>
          </div>
        </b-tab>

        <b-tab
          title="Avançado"
          class=""
          @click="openAvancado(), (submitOffer = true)"
        >
          <div class="tab-advanced">
            <b-tabs
              content-class="mt-3"
              active-nav-item-class="active-tab-advanced"
              active-tab-class="active-tab-advanced"
              nav-class="tab-advanced"
            >
              <div class="mt-4">
                <b-tab title="Geral" active>
                  <div class="mb-3">
                    <h5 class="mb-4">Páginas</h5>

                    <div class="d-flex align-items-end">
                      <b-col class="mt-3" cols="12" md="6">
                        <b-form-group
                          label="Página de Obrigado (padrão)"
                          label-for="thank_you_page"
                        >
                          <b-form-input
                            placeholder="http://www.exemplo.com"
                            name="thank_you_page"
                            v-model="product.thank_you_page"
                            type="text"
                          ></b-form-input>
                        </b-form-group>
                      </b-col>
                      <a
                        href="#"
                        class="nova-pagina"
                        @click.prevent="novaPaginaObrigado"
                        >Adicionar nova página</a
                      >
                    </div>
                    <div v-if="custom_thank_you_pages.length">
                      <b-row
                        data-anima="bottom"
                        class="item-page"
                        :class="{ mobile: isMobile }"
                        v-for="(_, index) in custom_thank_you_pages"
                        :key="index"
                      >
                        <b-col cols="12" md="3">
                          <b-form-group
                            label="Compras por"
                            :label-for="`type_page-${index}`"
                          >
                            <b-form-select
                              :options="type_pages"
                              id="type_pixel"
                              :name="`type_page-${index}`"
                              v-model="custom_thank_you_pages[index].type"
                            ></b-form-select>
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="5" class="ml-md-3">
                          <b-form-group
                            label="URL da página de obrigado"
                            :label-for="`url_page-${index}`"
                          >
                            <b-form-input
                              placeholder="http://www.exemplo.com"
                              :name="`url_page-${index}`"
                              id="pixel_id"
                              type="text"
                              v-model="custom_thank_you_pages[index].url"
                            ></b-form-input>
                          </b-form-group>
                        </b-col>
                        <div
                          @click.prevent="removePage(index)"
                          class="remove-page"
                        >
                          <a href="#">{{ isMobile ? "Remover" : "x" }}</a>
                        </div>
                      </b-row>
                    </div>

                    <b-col cols="12" md="5" class="mt-4">
                      <b-form-group
                        label="Endereço no Checkout"
                        label-for="is_checkout_address"
                      >
                        <b-form-checkbox
                          v-model="product.is_checkout_address"
                          name="is_checkout_address "
                          size="lg"
                          switch
                          @change="changeAddressCheckout"
                        >
                          <p class="info-checkbox">
                            Solicitar Endereço no Checkout
                            <span class="descricao-checkbox"
                              >Cliente deverá adicionar o endereço residencial
                              no ato da compra.</span
                            >
                          </p>
                        </b-form-checkbox>
                      </b-form-group>
                      <b-form-group
                        label="Utilizar Dados do Co-Produtor"
                        label-for="is_checkout_address"
                      >
                        <b-form-select
                          v-model="product.co_seller_display_id"
                          :options="optionsCoProdutor"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                      md="5"
                      class="mt-4"
                      v-if="permite_venda_inteligente"
                    >
                      <b-form-group
                        label="Venda inteligente"
                        label-for="allow_proposal"
                      >
                        <b-form-checkbox
                          v-model="product.smart_sale"
                          name="smart_sale "
                          size="lg"
                          switch
                          @change="changeSmartSale"
                        >
                          <p class="info-checkbox">
                            Tratar vendas de forma inteligente
                          </p>
                        </b-form-checkbox>
                      </b-form-group>
                    </b-col>

                    <b-col
                      class="mt-4"
                      cols="12"
                      md="5"
                      v-if="product.smart_sale"
                      data-anima="top"
                    >
                      <b-form-group
                        label="Parcelas da Venda Inteligente "
                        label-for="smart_sale_installments"
                        v-if="!product.smart_sale_dynamic_installments"
                      >
                        <b-form-select
                          name="smart_sale_installments"
                          v-model="product.smart_sale_installments"
                          :options="smart_sale_installments_options"
                          v-validate="'required'"
                        ></b-form-select>
                        <div class="mark-down mb-3">
                          <p class="info-mark">
                            Apenas para pagamentos com Cartão de Crédito
                          </p>
                        </div>

                        <b-form-invalid-feedback
                          :state="!errors.has('smart_sale_installments')"
                        >
                          Por favor, selecione as parcelas.
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col
                      class="mt-4"
                      cols="12"
                      md="5"
                      v-if="product.smart_sale"
                      data-anima="top"
                    >
                      <b-form-group
                        label="Parcelas Dinâmicas"
                        label-for="allow_proposal"
                      >
                        <b-form-checkbox
                          v-model="product.smart_sale_dynamic_installments"
                          name="smart_sale_dynamic_installments "
                          size="lg"
                          switch
                        >
                          <p class="info-checkbox">
                            Utilizar Parcelas Dinâmicas
                            <span class="descricao-checkbox"
                              >A venda inteligente será parcelada utilizando a
                              mesma quantidade de parcelas selecionadas pelo
                              cliente no Checkout.</span
                            >
                          </p>
                        </b-form-checkbox>
                      </b-form-group>
                    </b-col>
                  </div>

                  <div class="mt-4 mt-md-0">
                    <h5 class="mb-3 mt-4">Acesso rápido</h5>

                    <b-col class="mt-3 links-rapidos">
                      <router-link
                        :to="`/checkouts/?upsell=true&p_id=${product.id}`"
                        >Criar um Upsell</router-link
                      >
                      <router-link to="/checkouts/"
                        >Checkout Personalizado</router-link
                      >
                      <router-link
                        v-if="
                          product.type == 'TRANSACTION' &&
                            product.format !== 'PHYSICALPRODUCT'
                        "
                        :to="
                          `/links/?orderbump=true&p_id=${product.id}&p_name=${product.name}&p_type=${product.type}`
                        "
                        >Order Bump</router-link
                      >
                    </b-col>
                  </div>
                </b-tab>
                <b-tab title="Trackeamento">
                  <b-row>
                    <div class="mt-4 mt-md-0 col-12 col-md-6">
                      <b-col class="container-pixels" v-if="!loading_meta">
                        <div
                          class="
                            d-flex
                            justify-content-between
                            align-content-center
                            mb-3
                          "
                        >
                          <h5 class="m-0">Metas</h5>
                          <BaseButton
                            variant="secondary_outline"
                            id="metas"
                            class="btn-pixel"
                            v-if="!novaMeta"
                            @click="novaMeta = true"
                            >Nova Meta</BaseButton
                          >
                        </div>
                        <b-form data-anima="top" class="mb-5" v-if="novaMeta">
                          <b-col cols="12">
                            <b-form-group label="Chave" label-for="chave_meta">
                              <b-form-input
                                placeholder="Ex: utm_source"
                                name="chave_meta"
                                autocomplete="off"
                                v-model="newMeta.key"
                                type="text"
                              ></b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" class="mt-3">
                            <b-form-group label="Valor" label-for="valor_meta">
                              <b-form-input
                                placeholder="Ex: fb"
                                autocomplete="off"
                                name="valor_meta"
                                v-model="newMeta.value"
                                type="text"
                              ></b-form-input>
                            </b-form-group>
                          </b-col>

                          <div class="d-flex justify-content-end mt-3">
                            <BaseButton
                              variant="secondary_outline"
                              id="pixels"
                              class="btn-pixel"
                              @click="cancelMeta"
                              >Cancelar</BaseButton
                            >
                            <BaseButton
                              variant="link-info"
                              id="pixels"
                              class="btn-pixel ml-2"
                              @click="addMeta"
                              >Salvar</BaseButton
                            >
                          </div>
                        </b-form>
                        <div v-if="metas.length">
                          <div
                            v-for="(meta, index) in metas"
                            :key="meta.id"
                            class="pixel"
                            data-anima="top"
                          >
                            <div>
                              <h6>
                                <img src="@/assets/img/icons/formato.svg" />
                                #{{ meta.id }}
                              </h6>
                              <div>
                                <img
                                  src="@/assets/img/icons/editar.svg"
                                  class="icon-lixeira lixeira-pixel mr-2"
                                  :id="'btn-edit-' + meta.id"
                                  @click="editMeta(meta)"
                                />
                                <b-tooltip
                                  :target="'btn-edit-' + meta.id"
                                  title="Editar"
                                  placement="left"
                                />
                                <img
                                  src="@/assets/img/icons/lixeira.svg"
                                  class="icon-lixeira lixeira-pixel"
                                  :target="'btn-remove-' + meta.id"
                                  @click="removeMeta(meta, index)"
                                />
                                <b-tooltip
                                  :target="'btn-remove-' + meta.id"
                                  title="Remover"
                                />
                              </div>
                            </div>
                            <div>
                              <div class="pixel-type">
                                <p><b>Chave: </b> {{ meta.key }}</p>
                                <p
                                  v-if="meta.key === 'product_in_voxuy'"
                                  class="mt-2"
                                >
                                  <b>Valor: </b>{{ meta.value | treatVoxuy }}
                                </p>
                                <p v-else class="mt-2">
                                  <b>Valor: </b>{{ meta.value }}
                                </p>
                              </div>
                              <p>{{ meta.updated_at | datetime }}</p>
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <p class="nao-encontrado">Nenhuma Meta cadastrada</p>
                        </div>
                      </b-col>
                      <b-col class="mt-3 container-pixels" v-if="loading_meta">
                        <b-spinner label="Loading..."></b-spinner>
                      </b-col>
                    </div>

                    <!-- pixel -->
                    <div class="mt-4 mt-md-0 col-12 col-md-6">
                      <b-col class="container-pixels" v-if="!loading_pixel">
                        <div
                          class="
                            d-flex
                            justify-content-between
                            align-content-center
                            mb-3
                          "
                        >
                          <h5 class="m-0">Pixel</h5>
                          <BaseButton
                            variant="secondary_outline"
                            id="pixels"
                            class="btn-pixel"
                            v-if="!novoPixel"
                            @click="novoPixel = true"
                            >Novo Pixel</BaseButton
                          >
                        </div>
                        <b-form data-anima="top" class="mb-5" v-if="novoPixel">
                          <b-col cols="12">
                            <b-form-group label="Tipo" label-for="type_pixel">
                              <b-form-select
                                id="type_pixel"
                                name="type_pixel"
                                v-model="itemPixels.type"
                                @change="changePixelType"
                                placeholder="Selecione o tipo"
                                :options="typesPixels"
                              ></b-form-select>
                            </b-form-group>
                          </b-col>

                          <div class="d-flex justify-content-end">
                            <BaseButton
                              variant="secondary_outline"
                              id="pixels"
                              class="btn-pixel"
                              @click="cancelPixel"
                              >Cancelar</BaseButton
                            >
                          </div>
                        </b-form>
                        <div v-if="pixels.length">
                          <div
                            v-for="(pixel, index) in pixels"
                            :key="pixel.id"
                            class="pixel"
                          >
                            <div>
                              <!-- <span>{{ pixel.pixel_id }}</span> -->
                              <!-- <span>{{ pixel.type }}</span> -->

                              <img
                                class="iconePixel"
                                :src="iconPixel(pixel.type)"
                                :alt="pixel.type"
                              />
                              <div>
                                <div v-if="pixel.type == 'TIKTOK'" class="mr-2">
                                  <a
                                    href="#"
                                    @click.prevent="openTestTikTok"
                                    class="teste-tiktok"
                                    >Testar eventos</a
                                  >
                                </div>
                                <img
                                  src="@/assets/img/icons/editar.svg"
                                  class="icon-lixeira lixeira-pixel mr-2"
                                  :id="'btn-edit-' + pixel.id"
                                  @click="editPixelFb(pixel)"
                                />
                                <b-tooltip
                                  :target="'btn-edit-' + pixel.id"
                                  title="Editar"
                                  placement="left"
                                />
                                <img
                                  src="@/assets/img/icons/lixeira.svg"
                                  class="icon-lixeira lixeira-pixel"
                                  :target="'btn-remove-' + pixel.id"
                                  @click="
                                    removePixels(
                                      index,
                                      pixel.pixel_id,
                                      pixel.type,
                                      pixel.id
                                    )
                                  "
                                />
                                <b-tooltip
                                  :target="'btn-remove-' + pixel.id"
                                  title="Remover"
                                />
                              </div>
                            </div>
                            <div>
                              <div class="pixel-type">
                                <p><b>ID Pixel: </b> {{ pixel.pixel_id }}</p>
                                <p v-if="pixel.label">
                                  <b>Label: </b> {{ pixel.label }}
                                </p>
                                <p class="mt-2" v-if="pixel.domain">
                                  <b>Domínio: </b>{{ pixel.domain.domain }}
                                </p>
                              </div>
                              <p>{{ pixel.updated_at | datetime }}</p>
                            </div>
                            <div class="pixel-status mt-3">
                              <span
                                id="cname"
                                class="status-pixel"
                                :class="{ ativada: pixels[index].dns }"
                              >
                                CNAME
                                {{
                                  pixels[index].dns
                                    ? "verificado"
                                    : "aguardando verificação"
                                }}
                              </span>
                              <b-tooltip
                                v-if="!pixels[index].ssl"
                                target="cname"
                                title="Pixel funcionando com o CNAME em análise"
                              />
                              <span
                                id="ssl"
                                class="status-pixel"
                                :class="{ ativada: pixels[index].ssl }"
                              >
                                Certificado
                                {{
                                  pixels[index].ssl
                                    ? "verificado"
                                    : "aguardando verificação"
                                }}
                              </span>
                              <b-tooltip
                                v-if="!pixels[index].ssl"
                                target="ssl"
                                title="Pixel funcionando com o Certificado em análise"
                              />
                              <span
                                id="otimizacao"
                                class="status-pixel"
                                :class="{ ativada: pixel.optimization }"
                              >
                                Otimização
                                {{
                                  pixel.optimization ? "ativada" : "desativada"
                                }}
                              </span>
                              <b-tooltip
                                v-if="!pixel.optimization"
                                target="otimizacao"
                                :title="
                                  `Pixel funcionando com a otimização ${
                                    pixel.optimization
                                      ? 'ativada'
                                      : 'desativada'
                                  }`
                                "
                              />
                            </div>
                          </div>
                        </div>
                        <div v-else>
                          <p class="nao-encontrado">Nenhum Pixel cadastrados</p>
                        </div>
                      </b-col>
                      <b-col class="mt-3 container-pixels" v-if="loading_pixel">
                        <b-spinner label="Loading..."></b-spinner>
                      </b-col>
                    </div>
                  </b-row>
                </b-tab>
                <b-tab title="Fiscal">
                  <b-row class="mt-3">
                    <b-col cols="12" md="5" class="mt-2 mr-md-5">
                      <b-form-group label="" label-for="allow_proposal">
                        <h5 class="mb-4">Categoria Fiscal</h5>
                        <template>
                          <div>
                            <b-form-select
                              v-model="category_fiscal"
                              :options="[
                                { value: 'PRODUCT', text: 'Produto' },
                                { value: 'SERVICE', text: 'Serviço' },
                              ]"
                            ></b-form-select>
                          </div>
                        </template>
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                      md="5"
                      class="mt-2 ml-md-2"
                      v-if="category_fiscal === 'SERVICE'"
                    >
                      <b-form-group label="" label-for="allow_proposal">
                        <h5 class="mb-4">Código de serviço</h5>
                        <template>
                          <div>
                            <b-form-input
                              v-model="fiscal_code"
                              placeholder="Código de serviço"
                            ></b-form-input>
                          </div>
                        </template>
                      </b-form-group>
                    </b-col>
                    <b-row class="mt-3">
                      <b-col cols="12" md="6" class="mb-3">
                        <b-form-group label="" label-for="allow_proposal">
                          <h5 class="mb-4">Emissão após garantia</h5>
                          <b-form-checkbox
                            v-model="
                              fiscal_center_setting.generate_after_warranty
                            "
                            name="GENERATE_AFTER_WARRANTY"
                            size="lg"
                            switch
                          >
                            <p class="info-checkbox">
                              <span class="descricao-checkbox">
                                Configure para gerar nota fiscal apenas após o
                                período de garantia</span
                              >
                            </p>
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" md="6">
                        <b-form-group
                          label=""
                          label-for="isseu_when_the_sale_id_paid"
                        >
                          <h5>Emissão de notas por co-produtores.</h5>
                          <b-form-checkbox
                            name="CO_PRODUCER_WILL_ISSUE_NOTES"
                            v-model="
                              fiscal_center_setting.co_producer_will_issue_note
                            "
                            size="lg"
                            switch
                          >
                            <p class="info-checkbox">
                              <span class="descricao-checkbox">
                                Possibilita o co-produtor emitir a sua
                                porcentagem de notas (em relação a sua
                                participação no produto).
                              </span>
                            </p>
                          </b-form-checkbox>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-row>
                </b-tab>
              </div>
            </b-tabs>
          </div>
          <div data-anima="top" class="container-loading" v-if="loading">
            <b-spinner label="Loading..."></b-spinner>
          </div>
        </b-tab>
      </b-tabs>
      <!---->
      <div class="footer-edit" v-if="!error_get_product">
        <div class="footer-flex">
          <div class="links-uteis" v-if="product.affiliation">
            <p>Link de recrutamento</p>

            <a
              data-anima="bottom"
              v-if="!link_afiliado"
              href="#"
              @click.prevent="affiliateLink"
              :class="{ carregando: loading_affiliate }"
              >Clique aqui para gerar o link de recrutamento</a
            >
            <a
              data-anima="bottom"
              v-if="link_afiliado"
              :href="link_afiliado"
              target="_blank"
              :class="{ carregando: loading_affiliate }"
              >{{ link_afiliado }}</a
            >
          </div>

          <div class="links-uteis">
            <p>Link Checkout</p>
            <a
              :href="link_checkout"
              target="_blank"
              :class="{ carregando: loading }"
              >{{ link_checkout }}</a
            >
          </div>

          <BaseButton
            :disabled="loading"
            variant="link-info"
            v-if="submitOffer"
            @click="onSubmit"
            class="mt-4 mt-md-0"
          >
            Salvar Edição
          </BaseButton>
        </div>
      </div>
      <!-- modais componentes -->

      <co-seller
        @added="addedCoSeller"
        :product="product"
        :product_id="product"
      />

      <gdigital
        @close="atualizarListaG"
        :product="product"
        :gdigital="gdigital"
        @codeGdigitalChecked="codeGdigitalChecked"
      />
      <club
        @close="atualizarListaClub"
        :id_product="this.product.id"
        :name_product="this.product.name"
      />
      <envios
        @close="atualizarListaEnvios"
        :id_product="this.product.id"
        :name_product="this.product.name"
      />
      <opcoesEntregas
        :id_product="this.product.id"
        @memberkit-list="atualizarMemberkit"
        @sheets-list="atualizarSheets"
        @files-list="atualizarArquivos"
        @active-list="atualizarActive"
        @webhook-list="atualizarWebhook"
        @infusionsoft-list="atualizarInfusionsoft"
        @voxuy-list="atualizarVoxuy"
      />

      <EditorMarkDown :text_parent="text_md" from="edit" />
      <PixelFb
        @close="atualizaListaPixel"
        :product_id="product.id"
        :pixel_edit="pixel_edit"
        :type_pixel="pixel_type"
      />
    </div>
    <!--<CallEvaluation /> -->
    <ListagemEnderecos :enderecosResponse="enderecos" @close="getAddress" />
  </b-overlay>
</template>

<script>
// helps:
import Vue from "vue";
import { api_gdigital } from "@/services/Gdigital.js";
import { EventBus } from "@/main.js";

// componentes:
import VueUploadMultipleImage from "vue-upload-multiple-image";
import Multiselect from "vue-multiselect";
import ListagemCoseller from "@/components/Seller/Products/modals/ListagemCoSeller";
import ListagemGdigital from "@/components/Seller/Products/modals/ListagemGdigital";
import ListagemEnvios from "@/components/Seller/Products/modals/ListagemEnvios";
import ListagemClub from "@/components/Seller/Products/modals/ListagemClub";
import ListagemMemberkit from "@/components/Seller/Products/modals/ListagemMemberkit";
import ListagemActive from "@/components/Seller/Products/modals/ListagemActive";
import ListagemWebhook from "@/components/Seller/Products/modals/ListagemWebhook";
import ListagemSheets from "@/components/Seller/Products/modals/ListagemSheets";
import ListagemInfusionsoft from "@/components/Seller/Products/modals/ListagemInfusionsoft";
import ListagemVoxuy from "@/components/Seller/Products/modals/ListagemVoxuy";
import ListagemLeadLovers from "@/components/Seller/Products/modals/ListagemLeadlovers";
import ListagemArquivos from "@/components/Seller/Products/modals/ListagemArquivos";
import CoSeller from "@/components/Seller/Products/modals/coSeller";
import gdigital from "@/components/Seller/Products/modals/gdigital";
import club from "@/components/Seller/Products/modals/greennClub";
import envios from "@/components/Seller/Products/modals/greennEnvios";
import opcoesEntregas from "@/components/Seller/Products/modals/opcoesEntregas";
import EditorMarkDown from "@/components/Seller/Products/modals/EditorMarkDown";
import PixelFb from "@/components/Seller/Products/modals/PixelFb";
import ListagemEnderecos from "@/components/Seller/Address/ListagemEnderecos";
import Cobranca from "./Cobranca/Cobranca";
import { Money } from "v-money";

import PageNotFound from "@/views/PageNotFound.vue";

// services:
import AffiliateService from "@/services/resources/AffiliateService";
const serviceAffiliate = AffiliateService.build();

import ProductService from "@/services/resources/ProductService";
const serviceProduct = ProductService.build();

import CategoryService from "@/services/resources/CategoryService";
const serviceCategory = CategoryService.build();

import DomainPixelService from "@/services/resources/DomainPixelService";
const serviceDomain = DomainPixelService.build();

import ConversionPixelService from "@/services/resources/ConversionPixelService";
const serviceConversionPixel = ConversionPixelService.build();

import GetPixelService from "@/services/resources/GetPixelService";
const serviceGetPixel = GetPixelService.build();

import UserSettingsService from "@/services/resources/UserSettingsService";
const serviceUserSettings = UserSettingsService.build();

import AuthServiceEnvios from "@/services/resources/AuthServiceEnvios";
const serviceEnvios = AuthServiceEnvios.build();
import AddressService from "@/services/resources/AddressService";
const serviceAddress = AddressService.build();
import InfusionsoftService from "@/services/resources/InfusionsoftService";
const infusionsoftService = InfusionsoftService.build();

import ConnectionService from "@/services/resources/ConnectionService";
const connectionService = ConnectionService.build();

// fim services

export default {
  name: "EditProduct",
  data() {
    return {
      submitOffer: true,
      error_get_product: false,
      optionsCoProdutor: [{ value: null, text: "Não utilizar" }],
      text_md: "",
      type_open_md: "",
      existe_integracao: 0,
      loading: false,
      submit: false,
      slug: null,
      id_product: null,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      permite_parcela_boleto: false,
      has_greenn_envios: false,
      product: {
        name: "...",
        custom_thank_you_pages: [],
        max_comission: 100,
        description: "",
        instructions: "",
        charges: null,
        co_seller_display_id: null,
        type: null,
        period: 30,
        trial: null,
        // campos do produto fisico
        has_stock: false,
        stock: 0,
        can_be_gifted: false,
        category_id: null,
        warranty: null,
        warranty_checkout: true,
        method: null,
        smart_sale: false,
        smart_sale_installments: null,
        smart_sale_dynamic_installments: false,
        allowed_coupon: false,
        no_interest_installments: false,
        is_checkout_address: false,
        allow_proposal: false,
        affiliation: false,
        affiliation_approbation: false,
        affiliation_public: false,
        affiliation_proposal: false,
        comission: null,
        sales_page: "",
        thank_you_page: null,
        url_callback: null,
        bump_product_id: null,
        release: {},
        images: [],
        banner_checkout: [],
        metas: [],
        pixels: [],
        co_sellers: [],
        weight: 0,
        height: 0,
        width: 0,
        length: 0,
        has_shipping_fee: false,
        has_custom_shipping_address: false,
        address_product_id: null,
        pre_selected_installment: null,
      },
      categories: [],
      formats_product: [
        { value: null, text: "Selecione o tipo", disabled: true },
        { value: "INFOPRODUCT", text: "InfoProduto" },
        { value: "SERVICE", text: "Serviço" },
        { value: "PHYSICALPRODUCT", text: "Produto Físico" },
        { value: "EVENT", text: "Evento" },
        { value: "OTHERS", text: "Outros" },
      ],
      loading_affiliate: false,
      link_afiliado: "",
      link_checkout: "https://adm.voompcreators.com.br/",

      // helpers
      tabs: {
        parcerios: false,
        gdigital: false,
        club: false,
        envios: false,
        sheets: false,
        memberkit: false,
        webhook: false,
        files: false,
        activecampaing: false,
        infusionsoft: false,
        voxuy: false,
        leadlovers: false,
      },
      types: [
        { value: null, text: "Selecione o tipo" },
        { value: "TRANSACTION", text: "Valor único" },
        { value: "SUBSCRIPTION", text: "Assinatura" },
      ],
      periods: [
        { value: 30, text: "Mensal" },
        { value: 90, text: "Trimestral" },
        { value: 180, text: "Semestral" },
        { value: 365, text: "Anual" },
      ],
      custom_period: null,
      permite_periodo_personalizado: false,
      subscription_installments: [
        { value: null, text: "Selecione a parcelas", disabled: true },
        { value: 1, text: "Sem parcelas" },
      ],
      smart_sale_installments_options: [
        { value: null, text: "Selecione a parcelas", disabled: true },
        { value: 1, text: "Parcelada em 1x" },
      ],
      option_fixed_installments: [
        { value: null, text: "Parcelas livres" },
        { value: 12, text: "Parcela fixa de 12x" },
        { value: 11, text: "Parcela fixa de 11x" },
        { value: 10, text: "Parcela fixa de 10x" },
        { value: 9, text: "Parcela fixa de 9x" },
        { value: 8, text: "Parcela fixa de 8x" },
        { value: 7, text: "Parcela fixa de 7x" },
        { value: 6, text: "Parcela fixa de 6x" },
        { value: 5, text: "Parcela fixa de 5x" },
        { value: 4, text: "Parcela fixa de 4x" },
        { value: 3, text: "Parcela fixa de 3x" },
        { value: 2, text: "Parcela fixa de 2x" },
      ],
      option_pre_selected_installment: [
        { value: null, text: "Selecione uma parcela", disabled: true },
        { value: 12, text: "Parcela 12x" },
        { value: 11, text: "Parcela 11x" },
        { value: 10, text: "Parcela 10x" },
        { value: 9, text: "Parcela 9x" },
        { value: 8, text: "Parcela 8x" },
        { value: 7, text: "Parcela 7x" },
        { value: 6, text: "Parcela 6x" },
        { value: 5, text: "Parcela 5x" },
        { value: 4, text: "Parcela 4x" },
        { value: 3, text: "Parcela 3x" },
        { value: 2, text: "Parcela 2x" },
        { value: 1, text: "Parcela 1x" },
        { value: 0, text: "Sem parcelas" },
      ],
      option_max_installments: [
        { value: null, text: "Selecione a parcelas", disabled: true },
        { value: 12, text: "Parcelas até em 12x" },
        { value: 11, text: "Parcelas até em 11x" },
        { value: 10, text: "Parcelas até em 10x" },
        { value: 9, text: "Parcelas até em 9x" },
        { value: 8, text: "Parcelas até em 8x" },
        { value: 7, text: "Parcelas até em 7x" },
        { value: 6, text: "Parcelas até em 6x" },
        { value: 5, text: "Parcelas até em 5x" },
        { value: 4, text: "Parcelas até em 4x" },
        { value: 3, text: "Parcelas até em 3x" },
        { value: 2, text: "Parcelas até em 2x" },
        { value: 1, text: "Sem parcelas" },
      ],
      option_max_comission: [
        { value: 100, text: "Sempre receber a comissão", selected: true },
        { value: 12, text: "Receber até a 12ª parcela" },
        { value: 11, text: "Receber até a 11ª parcela" },
        { value: 10, text: "Receber até a 10ª parcela" },
        { value: 9, text: "Receber até a 9ª parcela" },
        { value: 8, text: "Receber até a 8ª parcela" },
        { value: 7, text: "Receber até a 7ª parcela" },
        { value: 6, text: "Receber até a 6ª parcela" },
        { value: 5, text: "Receber até a 5ª parcela" },
        { value: 4, text: "Receber até a 4ª parcela" },
        { value: 3, text: "Receber até a 3ª parcela" },
        { value: 2, text: "Receber até a 2ª parcela" },
        { value: 1, text: "Receber até a 1ª parcela" },
      ],
      methods: [],

      co_sellers_list: [],
      listaGDigital: [],
      gdigital: {
        integration: false,
        token: "",
        auth: "",
        email: "",
        blogs_gdigital: [],
        forms: [],
      },
      files_list: [],

      // pixels
      pixels: [],
      loading_pixel: false,
      novoPixel: false,
      pixel_edit: {
        item: {},
        edit: false,
      },
      pixel_type: "",
      itemPixels: {
        pixel_id: "",
        type: "",
      },
      typesPixels: [
        { value: null, text: "Selecione o Tipo", disabled: true },
        { value: "FACEBOOK", text: "Facebook" },
        { value: "GOOGLEADWORDS", text: "Google AdWords" },
        { value: "GOOGLETAGMANAGER", text: "Google Tag Manager" },
        { value: "GOOGLEANALYTICS", text: "Google Analytics" },
        { value: "TIKTOK", text: "TikTok" },
      ],
      // meta
      loading_meta: false,
      novaMeta: false,
      metas: [],
      newMeta: {
        key: null,
        value: null,
      },

      // notas
      fiscal_center_setting: {
        generate_after_warranty: true,
        co_producer_will_issue_note: false,
      },
      client: {
        width: 0,
      },
      category_fiscal: "PRODUCT",
      fiscal_code: null,
      permite_venda_inteligente: false,
      endereco_principal: null,
      enderecos: [],
      enderecos_select: [
        { value: null, text: "Selecione o Endereço", disabled: true },
      ],
      loading_address: false,

      // pagina de obrigado
      custom_thank_you_pages: [],

      type_pages: [
        { value: "CREDIT_CARD", text: "Cartão de Crédito" },
        { value: "BOLETO", text: "Boleto" },
        { value: "TWO_CREDIT_CARD", text: "2 Cartões" },
        { value: "PIX", text: "PIX" },
        { value: "PAYPAL", text: "PAYPAL" },
      ],
      validate_pre_selected: false,
      validate_pre_selected_two: false,
    };
  },
  components: {
    PageNotFound,
    Cobranca,
    Money,
    VueUploadMultipleImage,
    Multiselect,
    ListagemCoseller,
    ListagemGdigital,
    ListagemEnvios,
    ListagemClub,
    ListagemMemberkit,
    ListagemActive,
    ListagemWebhook,
    ListagemSheets,
    ListagemArquivos,
    CoSeller,
    gdigital,
    club,
    envios,
    opcoesEntregas,
    EditorMarkDown,
    PixelFb,
    ListagemEnderecos,
    ListagemInfusionsoft,
    ListagemVoxuy,
    ListagemLeadLovers,
  },
  computed: {
    userId() {
      return this.$store.getters.getProfile.id;
    },
    userEnvios() {
      return process.env.USER_ENVIOS;
    },
    baseURL() {
      return process.env.VUE_SALES_PAGE;
    },
    clubIntegration() {
      return this.$store.getters.userClub;
    },
    isMobile() {
      return this.client.width <= 768;
    },
  },
  filters: {
    treatVoxuy: (val) => {
      let parsed = JSON.parse(val);
      let treated = `${parsed.name} : ${parsed.plan_id}`;
      return treated;
    },
  },
  methods: {
    changeValue(v) {
      this.submitOffer = !v;
    },
    changeJurosBoleto(e) {
      if (
        this.permite_parcela_boleto &&
        this.product.no_interest_installments
      ) {
        this.$bvToast.toast(
          "Vendedor assumirá o juros apenas Cartão de Crédito. Outros métodos continuaram com o juros",
          {
            title: "Produto",
            variant: "info",
            autoHideDelay: 9000,
            appendToast: true,
          }
        );
      }
    },
    checkMethodTransaction() {
      if (this.product.format == "PHYSICALPRODUCT") {
        if (this.product.has_shipping_fee == 1) {
          this.methods = [
            { name: "Cartão de crédito", value: "CREDIT_CARD" },
            { name: "Pix", value: "PIX" },
            { name: "Boleto", value: "BOLETO" },
            { name: "Dois Cartões", value: "TWO_CREDIT_CARDS" },
          ];
        } else {
          this.methods = [
            { name: "Cartão de crédito", value: "CREDIT_CARD" },
            { name: "Boleto", value: "BOLETO" },
            { name: "Pix", value: "PIX" },
            { name: "PayPal", value: "PAYPAL" },
            { name: "Dois Cartões", value: "TWO_CREDIT_CARDS" },
          ];
        }
      } else {
        this.methods = [
          { name: "Cartão de crédito", value: "CREDIT_CARD" },
          { name: "Boleto", value: "BOLETO" },
          { name: "Pix", value: "PIX" },
          { name: "PayPal", value: "PAYPAL" },
          { name: "Dois Cartões", value: "TWO_CREDIT_CARDS" },
        ];
      }
      // this.product.method = this.methods;
    },
    changeSmartSale(v) {
      if (!v) {
        this.product.smart_sale_installments = null;
      }
    },
    removePage(index) {
      this.custom_thank_you_pages.splice(index, 1);
    },
    novaPaginaObrigado() {
      // this.nova_pagina = true;
      this.custom_thank_you_pages.push({
        type: "",
        url: "",
      });
    },
    openProdutoFisico() {
      // if (this.product.has_shipping_fee) {
      this.getAddress();
      // }
    },
    changeFrete(e) {
      if (e) this.getAddress();

      this.checkMethodTransaction();
    },
    openAddress() {
      this.$bvModal.show("modal-address");
    },
    mountAddress(endereco) {
      return `📍 ${endereco.street}, ${endereco.number}, ${endereco.neighborhood}, ${endereco.city}/${endereco.state}`;
    },
    getAddress() {
      this.loading_address = true;
      this.endereco_principal = null;

      this.enderecos = [];
      this.enderecos_select = [
        { value: null, text: "Selecione o Endereço", disabled: true },
      ];

      serviceAddress
        .search()
        .then((response) => {
          this.enderecos = response;
          this.enderecos.forEach((item) => {
            this.enderecos_select.push({
              value: item.id,
              text: this.mountAddress(item),
            });
          });
        })
        .finally(() => {
          this.loading_address = false;
        });
    },
    changeFormatProduct(e) {
      if (e == "PHYSICALPRODUCT") {
        this.methods = [
          { name: "Cartão de crédito", value: "CREDIT_CARD" },
          { name: "Boleto", value: "BOLETO" },
          { name: "Pix", value: "PIX" },
          { name: "Dois Cartões", value: "TWO_CREDIT_CARDS" },
        ];
        this.permite_parcela_boleto = false;
        let methods = this.product.method;
        for (let i = 0; i < methods.length; i++) {
          const element = methods[i];
          if (element.value === "PAYPAL") {
            methods.splice(i, 1);
          }
        }
        this.product.method = methods;
        this.$bvToast.toast(
          "Configure o estoque e frete do seu produto na aba 'Produto Físico'",
          {
            title: "Produto",
            variant: "info",
            autoHideDelay: 5000,
            appendToast: true,
          }
        );
        if (this.product.type != "TRANSACTION") {
          this.$bvToast.toast(
            "Produto físico não pode ser vendido como Assinatura, apenas como Venda única. Verifique antes de salvar",
            {
              title: "Produto",
              variant: "info",
              autoHideDelay: 5000,
              appendToast: true,
            }
          );
        } else {
          this.types = [
            { value: null, text: "Selecione o tipo" },
            { value: "TRANSACTION", text: "Valor único" },
            {
              value: "SUBSCRIPTION",
              text: "Assinatura (apenas para produtos digitais)",
              disabled: true,
            },
          ];
        }
      } else {
        this.types = [
          { value: null, text: "Selecione o tipo" },
          { value: "TRANSACTION", text: "Valor único" },
          { value: "SUBSCRIPTION", text: "Assinatura" },
        ];
        if (this.product.type == "SUBSCRIPTION") {
          this.methods = [
            { name: "Cartão de crédito", value: "CREDIT_CARD" },
            { name: "Boleto", value: "BOLETO" },
          ];
        } else if (this.product.type == "TRANSACTION") {
          this.methods = [
            { name: "Cartão de crédito", value: "CREDIT_CARD" },
            { name: "Boleto", value: "BOLETO" },
            { name: "Pix", value: "PIX" },
            { name: "PayPal", value: "PAYPAL" },
            { name: "Dois Cartões", value: "TWO_CREDIT_CARDS" },
          ];
        }
        this.permite_parcela_boleto = false;
      }
    },
    fetchUserSettings() {
      let data = {
        id: this.userId || JSON.parse(localStorage.user).id,
      };

      serviceUserSettings
        .read(data)
        .then((response) => {
          response.forEach((item) => {
            if (item.key == "SMART_SALE" && item.value == "1") {
              this.permite_venda_inteligente = true;
            }
            if (item.key == "PRODUCT_CUSTOM_PERIOD" && item.value == "1") {
              this.permite_periodo_personalizado = true;
            }
          });
        })
        .finally(() => {
          if (this.permite_periodo_personalizado) {
            this.periods.push({ value: "CUSTOM", text: "Personalizado" });
          }
        });
    },
    openMD(type) {
      this.type_open_md = type;
      if (type == "descricao") {
        this.text_md = this.product.description;
      } else if (type == "instrucoes") {
        this.text_md = this.product.instructions;
      }

      this.openModal("editor-markdown");
    },
    changeAddressCheckout(e) {
      if (!e && this.product.format == "PHYSICALPRODUCT") {
        this.product.is_checkout_address = true;
        this.$bvToast.toast(
          "Produtos do tipo Físico obrigatoriamente deve solicitar o endereço no checkout",
          {
            title: "Produto",
            variant: "info",
            autoHideDelay: 5000,
            appendToast: true,
          }
        );
      }
    },
    iconPixel(type) {
      if (type) {
        return require(`@/assets/img/${type.toLowerCase()}_icone.png`);
      } else return false;
    },
    initSmartSale() {
      this.smart_sale_installments_options = [];
      for (let i = 0; i < 13; i++) {
        if (i < 1) {
          this.smart_sale_installments_options.push({
            value: null,
            text: "Selecione a parcelas",
            disabled: true,
          });
        } else {
          this.smart_sale_installments_options.push({
            value: i,
            text: `Parcelada em ${i}x`,
          });
        }
      }
    },
    changeTypes(type) {
      this.product.smart_sale_installments = null;

      if (type == "SUBSCRIPTION") {
        this.methods = [
          { name: "Cartão de crédito", value: "CREDIT_CARD" },
          { name: "Boleto", value: "BOLETO" },
        ];
        this.product.method = [
          { name: "Cartão de crédito", value: "CREDIT_CARD" },
          { name: "Boleto", value: "BOLETO" },
        ];
      } else if (type == "TRANSACTION") {
        this.methods = [
          { name: "Cartão de crédito", value: "CREDIT_CARD" },
          { name: "Boleto", value: "BOLETO" },
          { name: "Pix", value: "PIX" },
          { name: "PayPal", value: "PAYPAL" },
          { name: "Dois Cartões", value: "TWO_CREDIT_CARDS" },
        ];
      }
      if (type == "TRANSACTION") {
        this.option_fixed_installments = [
          { value: null, text: "Parcelas livres" },
          { value: 12, text: "Parcela fixa de 12x" },
          { value: 11, text: "Parcela fixa de 11x" },
          { value: 10, text: "Parcela fixa de 10x" },
          { value: 9, text: "Parcela fixa de 9x" },
          { value: 8, text: "Parcela fixa de 8x" },
          { value: 7, text: "Parcela fixa de 7x" },
          { value: 6, text: "Parcela fixa de 6x" },
          { value: 5, text: "Parcela fixa de 5x" },
          { value: 4, text: "Parcela fixa de 4x" },
          { value: 3, text: "Parcela fixa de 3x" },
          { value: 2, text: "Parcela fixa de 2x" },
        ];
        this.option_pre_selected_installment = [
          { value: null, text: "Selecione uma parcela", disabled: true },
          { value: 12, text: "Parcela 12x" },
          { value: 11, text: "Parcela 11x" },
          { value: 10, text: "Parcela 10x" },
          { value: 9, text: "Parcela 9x" },
          { value: 8, text: "Parcela 8x" },
          { value: 7, text: "Parcela 7x" },
          { value: 6, text: "Parcela 6x" },
          { value: 5, text: "Parcela 5x" },
          { value: 4, text: "Parcela 4x" },
          { value: 3, text: "Parcela 3x" },
          { value: 2, text: "Parcela 2x" },
          { value: 1, text: "Parcela 1x" },
          { value: 0, text: "Sem parcelas" },
        ];
        this.customPreInstallmentsOptions();
        this.initSmartSale();
      } else {
        this.product.period = 30;
        this.smart_sale_installments_options = [
          { value: null, text: "Selecione a parcelas", disabled: true },
          { value: 1, text: "Parcelada em 1x" },
        ];
        this.customPreInstallmentsOptionsSubscription();
      }
    },
    changeParcelasSub(period, init = false) {
      if (this.permite_periodo_personalizado) {
        this.custom_period = null;
      }

      if (!init) {
        this.product.smart_sale_installments = null;
      }

      this.subscription_installments = [
        { value: null, text: "Selecione a parcelas", disabled: true },
        { value: 1, text: "Sem parcelas" },
      ];
      this.smart_sale_installments_options = [
        { value: null, text: "Selecione a parcelas", disabled: true },
        { value: 1, text: "Parcelada em 1x" },
      ];

      if (this.product.type == "SUBSCRIPTION") {
        this.option_fixed_installments = [
          { value: null, text: "Parcelas livres" },
        ];
      }

      if (period > 30) {
        let totalParcelas = 0;
        switch (period) {
          case 90: {
            totalParcelas = 3;
            break;
          }
          case 180: {
            totalParcelas = 6;
            break;
          }
          case 365: {
            totalParcelas = 12;
            break;
          }
        }

        for (let i = 1; i <= totalParcelas; i++) {
          if (i > 1) {
            this.subscription_installments.push({
              value: i,
              text: `Parcelada em ${i}x`,
            });

            if (this.product.type == "SUBSCRIPTION") {
              this.option_fixed_installments.push({
                value: i,
                text: `Parcela fixa de ${i}x`,
              });
            }
          }
        }

        if (this.product.type == "SUBSCRIPTION") {
          this.smart_sale_installments_options = this.subscription_installments;
          this.smart_sale_installments_options[1].text = "Parcelada em 1x";

          this.product.fixed_installments = null;
        }
      } else if (period == "CUSTOM") {
        this.initSmartSale();

        for (let i = 1; i <= 12; i++) {
          if (i > 1) {
            this.subscription_installments.push({
              value: i,
              text: `Parcelada em ${i}x`,
            });

            if (this.product.type == "SUBSCRIPTION") {
              this.option_fixed_installments.push({
                value: i,
                text: `Parcela fixa de ${i}x`,
              });
            }
          }
        }
      }

      if (this.validate_pre_selected) {
        this.product.pre_selected_installment = null;
      }
      this.customPreInstallmentsOptionsSubscription();
      if (this.product.max_subscription_installments)
        this.customPreInstallmentsOptionsSubscriptionAss();
      this.validate_pre_selected = true;
    },

    loginClub() {
      this.$store.dispatch("clubLogin").catch(function(err) {});
    },
    loginEnvios() {
      serviceEnvios
        .toClub()
        .then((resp) => {
          if (resp.string) {
            window
              .open(
                process.env.VUE_ENVIOS_API_HOST +
                  "/login?rdr=" +
                  resp.string +
                  "&type=greenn",
                "_blank"
              )
              .focus();
          }
        })
        .catch(() => {});
    },
    openAvancado() {
      this.fetchPixels();
      this.fetchMetas();
    },
    // metas
    fetchMetas() {
      if (!this.metas.length) {
        this.loading_meta = true;
        let data = {
          id: `${this.product.id}/meta`,
        };

        this.metas = [];

        serviceProduct
          .read(data)
          .then((response) => {
            if (response.length) {
              this.metas = response.reverse();
            } else {
              this.metas = response;
            }
          })
          .finally(() => {
            this.loading_meta = false;
          });
      }
    },

    // metas
    editMeta(meta) {
      this.novaMeta = true;
      this.newMeta = meta;
    },
    removeMeta(meta, index_lista) {
      Vue.swal({
        title: "Remover Meta ",
        text: `Deseja continuar removendo a meta ${meta.key} | ${meta.value}?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          let data = {
            id: `${this.product.id}/meta/${meta.id}`,
          };

          serviceProduct
            .destroy(data)
            .then((response) => {
              if (response.success) {
                // atualizando front
                this.metas.splice(index_lista, 1);
                this.$bvToast.toast("Meta removido com sucesso", {
                  title: "Meta",
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
              } else {
                this.$bvToast.toast("Houve um erro ao remover a meta", {
                  title: "Meta",
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
              }
            })
            .finally(() => {
              this.loading_meta = false;
            });
        }
      });
    },
    addMeta() {
      this.loading_meta = true;

      if (!this.newMeta.id) {
        serviceProduct
          .create(this.newMeta, `${this.product.id}/meta`)
          .then((response) => {
            this.metas.unshift(response);
            this.cancelMeta();
            this.$bvToast.toast("Meta adicionado com sucesso", {
              title: "Meta",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });
          })
          .finally(() => {
            this.loading_meta = false;
          });
      } else {
        serviceProduct
          .update(this.newMeta, `${this.product.id}/meta/${this.newMeta.id}`)
          .then(() => {
            this.cancelMeta();
            this.fetchMetas();
            this.$bvToast.toast("Meta atualizado com sucesso", {
              title: "Meta",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });
          })
          .finally(() => {
            this.loading_meta = false;
          });
      }
    },

    cancelMeta() {
      this.novaMeta = false;
      this.newMeta = {
        key: null,
        value: null,
      };
    },

    openTestTikTok() {
      window.open(`${this.baseURL}/${this.product.id}/?debugPixel=true`);
    },
    atualizaListaPixel(edit) {
      this.itemPixels = {
        pixel_id: "",
        type: null,
      };
      if (edit) {
        this.pixels = [];
        this.fetchPixels();
      }
    },
    // pixels
    fetchPixels() {
      if (!this.pixels.length) {
        let data = {
          id: this.product.id,
        };
        serviceGetPixel.read(data).then((response) => {
          if (response.length) {
            this.pixels = response.reverse().map((item) => ({
              ...item,
              ssl: 0,
              dns: 0,
            }));

            this.pixel_edit = {
              item: {},
              edit: false,
            };
            this.pixels.forEach((item, index) => {
              this.validaDomainPixel(item, index);
            });
          } else {
            this.pixels = [];
            this.loading_pixel = false;
          }
        });
      }
    },
    validaDomainPixel(pixel, index) {
      this.loading_pixel = true;

      let data = {
        domain: pixel.domain ? pixel.domain.domain : "",
      };

      if (data.domain) {
        serviceDomain
          .search(data)
          .then((response) => {
            pixel.dns = response.dns;
            pixel.ssl = response.ssl;
          })
          .finally(() => {
            this.loading_pixel = false;
          });
      } else {
        this.loading_pixel = false;
      }
    },
    changePixelType(e) {
      this.novoPixel = false;
      this.pixel_edit = {
        item: {},
        edit: false,
      };
      this.pixel_type = e;
      this.openModal("pixel-fb");
      this.itemPixels.type = null;
    },
    cancelPixel() {
      this.novoPixel = false;
      this.itemPixels = {
        pixel_id: "",
        type: null,
      };
    },
    editPixelFb(pixel) {
      this.pixel_type = pixel.type;
      this.pixel_edit = {
        item: pixel,
        edit: true,
      };
      this.openModal("pixel-fb");
    },
    removePixelItem(id_pixel) {
      this.loading_pixel = true;
      let data = {
        id: id_pixel,
      };
      serviceConversionPixel
        .destroy(data)
        .then(() => {
          this.$bvToast.toast("Pixel removido com sucesso", {
            title: "Pixel",
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .finally(() => {
          this.loading_pixel = false;
        });
    },

    removePixels(index_lista, pixel_id, type, id_pixel) {
      Vue.swal({
        title: "Remover Pixel ",
        text: `Deseja continuar removendo o pixel ${pixel_id}?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Remover",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          // atualizando front
          this.pixels.splice(index_lista, 1);
          this.removePixelItem(id_pixel);
        }
      });
    },
    // coprodutor
    initParceiros() {
      this.tabs.parceiros = true;
    },

    addedCoSeller(data) {
      this.product.co_sellers = [];
      for (let i = 0; i < data.length; i++) {
        this.product.co_sellers.unshift({
          user_id: data[i].user_id.id,
          percentage: parseFloat(String(data[i].percentage).replace(",", ".")),
          type: data[i].type,
        });
        this.co_sellers_list.unshift({
          id: data[i].id,
          user_id: data[i].user_id.id,
          percentage: parseFloat(String(data[i].percentage).replace(",", ".")),
          type: data[i].type,
          user: {
            name: data[i].user.name,
          },
        });
      }
      console.log("co_sellers_list 2", this.co_sellers_list);

      var co_sellers_list = this.co_sellers_list;
      console.log("co-se-l", co_sellers_list);
      var co_sellers_list2 = [];
      co_sellers_list.forEach((element) => {
        co_sellers_list2.push({
          value: element.user.id,
          text: element.user.name,
        });
      });
      var optionsCoProdutor = this.optionsCoProdutor;
      var result = optionsCoProdutor.concat(co_sellers_list2);
      this.optionsCoProdutor = result;

      this.tabs.parceiros = false;
      setTimeout(() => {
        this.tabs.parceiros = true;
      }, 300);

      EventBus.$emit("atualizaPorcentagem");
    },

    // integrações / entregas
    initIntegracoes() {
      if (
        !this.tabs.memberkit &&
        !this.tabs.webhook &&
        !this.tabs.activecampaing &&
        !this.tabs.sheets &&
        !this.tabs.files &&
        !this.tabs.infusionsoft &&
        !this.tabs.voxuy &&
        !this.tabs.leadlovers
      ) {
        this.tabs.memberkit = false;
        this.tabs.webhook = false;
        this.tabs.sheets = false;
        this.tabs.activecampaing = false;
        this.tabs.files = false;
        this.tabs.infusionsoft = false;
        this.tabs.voxuy = false;
        this.tabs.leadlovers = false;
        setTimeout(() => {
          this.tabs.memberkit = true;
          this.tabs.webhook = true;
          this.tabs.sheets = true;
          this.tabs.activecampaing = true;
          this.tabs.files = true;
          this.tabs.infusionsoft = true;
          this.tabs.voxuy = true;
          this.tabs.leadlovers = true;
        });
      }
    },

    // arquivos
    atualizarArquivos(data) {
      this.loading = true;
      this.tabs.files = false;

      // for (let i = 0; i < data.length; i++) {
      this.files_list.unshift({
        id: data.id,
        name: data.name,
        created_at: data.created_at,
      });
      // }

      this.tabs.files = true;
      this.loading = false;
    },

    atualizarSheets() {
      this.loading = true;
      this.tabs.sheets = false;
      setTimeout(() => {
        this.tabs.sheets = true;
      });
      this.loading = false;
    },

    // memberkit
    atualizarMemberkit() {
      this.loading = true;
      this.tabs.memberkit = false;
      setTimeout(() => {
        this.tabs.memberkit = true;
      });
      this.loading = false;
    },
    // active
    atualizarActive() {
      this.loading = true;
      this.tabs.activecampaing = false;
      setTimeout(() => {
        this.tabs.activecampaing = true;
      });
      this.loading = false;
    },

    // webhook
    atualizarWebhook() {
      this.loading = true;
      this.tabs.webhook = false;
      setTimeout(() => {
        this.tabs.webhook = true;
      });
      this.loading = false;
    },

    // infusionsoft
    atualizarInfusionsoft() {
      this.loading = true;
      this.tabs.infusionsoft = false;
      setTimeout(() => {
        this.tabs.infusionsoft = true;
      });
      this.loading = false;
    },

    // infusionsoft
    atualizarVoxuy() {
      this.loading = true;
      this.tabs.voxuy = false;
      setTimeout(() => {
        this.tabs.voxuy = true;
      });
      this.loading = false;
    },

    // greennClub
    initClub() {
      if (!this.tabs.club) {
        this.tabs.club = true;
      }
    },
    //GreennEnvios
    initEnvios() {
      if (!this.tabs.envios) {
        this.tabs.envios = true;
      }
    },
    atualizarListaClub() {
      this.loading = true;
      this.tabs.club = false;
      setTimeout(() => {
        this.tabs.club = true;
      });
      this.loading = false;
    },
    atualizarListaEnvios() {
      this.loading = true;
      this.tabs.envios = false;
      setTimeout(() => {
        this.tabs.envios = true;
      });
      this.loading = false;
    },
    // gdigital
    codeGdigitalChecked() {
      api_gdigital.get("/integration").then((r) => {
        if (r.data.length) {
          this.gdigital.integration = true;
          this.gdigital.auth = r.data[0].auth;
          this.gdigital.token = r.data[0].token_greenn;
          this.gdigital.email = r.data[0].email;
          this.gdigital.blogs_gdigital = [];
          this.getBlogsGdigital();
        }
      });
    },
    initGdigital() {
      // validar integração
      if (!this.tabs.gdigital) {
        api_gdigital
          .get("/integration")
          .then((r) => {
            if (r.data.length) {
              this.gdigital.integration = true;
              this.gdigital.auth = r.data[0].auth;
              this.gdigital.token = r.data[0].token_greenn;
              this.gdigital.email = r.data[0].email;
              this.getBlogsGdigital();
            } else {
              this.gdigital.integration = false;
            }
          })
          .catch(() => {
            this.gdigital.integration = false;
          })
          .finally(() => {
            this.tabs.gdigital = true;
          });
      }
    },

    getBlogsGdigital() {
      const email = this.gdigital.email;

      api_gdigital.get(`/blogs?email=${email}`).then((response) => {
        if (response.data.length) {
          [...response.data].forEach((item) => {
            this.gdigital.blogs_gdigital.push({
              value: `${item.blog_id}|${item.auth}`,
              text: item.blogName || item.domain,
            });
          });
        } else {
          this.$bvToast.toast(response.data.error, {
            title: "G Digital",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        }
      });
    },

    atualizarListaG() {
      this.loading = true;
      this.tabs.gdigital = false;
      setTimeout(() => {
        this.tabs.gdigital = true;
      });
      this.loading = false;
    },

    // helper para abrir modais
    openModal(modal) {
      this.$bvModal.show(modal);
    },

    // inicializando tabs
    initCobranca() {
      this.loading = true;
      this.product.method =
        this.product.method || "CREDIT_CARD,PIX,BOLETO,PAYPAL,TWO_CREDIT_CARDS";

      if (!Array.isArray(this.product.method)) {
        this.product.method = this.product.method.split(",").map((item) => {
          switch (item) {
            default:
            case "ALL": {
              if (this.product.type == "TRANSACTION") {
                return "CREDIT_CARD,PIX,BOLETO,PAYPAL,TWO_CREDIT_CARDS";
              } else if (this.product.type == "SUBSCRIPTION") {
                return "CREDIT_CARD,BOLETO";
              }
              break;
            }
            case "CREDIT_CARD": {
              return {
                name: "Cartão de crédito",
                value: "CREDIT_CARD",
              };
            }
            case "BOLETO": {
              return { name: "Boleto", value: "BOLETO" };
            }
            case "PIX": {
              if (this.product.type == "TRANSACTION") {
                return { name: "Pix", value: "PIX" };
              }
              break;
            }
            case "PAYPAL": {
              if (this.product.type == "TRANSACTION") {
                return { name: "PayPal", value: "PAYPAL" };
              }
              break;
            }
            case "TWO_CREDIT_CARDS": {
              if (this.product.type == "TRANSACTION") {
                return {
                  name: "Dois Cartões",
                  value: "TWO_CREDIT_CARDS",
                };
              }
              break;
            }
          }
        });
      }
      if (
        this.product.method == "CREDIT_CARD,PIX,BOLETO,PAYPAL,TWO_CREDIT_CARDS"
      ) {
        if (this.product.type == "TRANSACTION") {
          this.product.method = [
            { name: "Cartão de crédito", value: "CREDIT_CARD" },
            { name: "Boleto", value: "BOLETO" },
            { name: "Pix", value: "PIX" },
            { name: "PayPal", value: "PAYPAL" },
            { name: "Dois Cartões", value: "TWO_CREDIT_CARDS" },
          ];
        } else if (this.product.type == "SUBSCRIPTION") {
          this.product.method = [
            { name: "Cartão de crédito", value: "CREDIT_CARD" },
            { name: "Boleto", value: "BOLETO" },
          ];
        }
      }
      this.loading = false;

      this.product.method = [...this.product.method].filter((item) => {
        if (item) return item;
      });
    },
    // enviado para todos os produtos
    todosProdutos() {
      this.$router.push("/produtos");
    },
    // iniciando edição do produto
    async onSubmit() {
      this.submit = true;

      if (this.product.warranty < 7) {
        this.$bvToast.toast(
          "Prazo de reembolso deve ser maior do que 7 dias. Preecha corretamente",
          {
            title: "Produto",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          }
        );
        this.submit = false;
        return;
      }

      this.$validator.validateAll().then(async (result) => {
        if (result) {
          if (this.product.type == "SUBSCRIPTION" && this.amount_changed) {
            // Show confirmation after try to change subscription's price
            let result = await this.showConfirmSubscriptionChanged();
            if (!result) {
              this.submit = false;
              return;
            }
          }

          // validação amount
          // if (!this.product.amount) {
          //   this.$bvToast.toast(
          //     "Preço deve ser maior do que 0. Preecha corretamente",
          //     {
          //       title: "Produto",
          //       variant: "danger",
          //       autoHideDelay: 5000,
          //       appendToast: true,
          //     }
          //   );
          //   document.querySelector("#amount").focus();
          //   this.submit = false;
          //   return;
          // }
          if (
            this.product.type == "SUBSCRIPTION" &&
            this.product.format == "PHYSICALPRODUCT"
          ) {
            this.$bvToast.toast(
              "Produto físico não pode ser vendido como Assinatura, apenas como Venda única. Verifique em Cobrança > Tipo de cobrança",
              {
                title: "Produto",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              }
            );
            this.submit = false;
            return;
          }

          if (this.product.format == "PHYSICALPRODUCT") {
            this.permite_parcela_boleto = false;
            let methods = this.product.method;
            for (let i = 0; i < methods.length; i++) {
              const element = methods[i];
              if (element.value === "PAYPAL") {
                methods.splice(i, 1);
              }
            }
          }

          // validação descricao
          if (this.product.description.length < 200) {
            this.$bvToast.toast(
              "O campo descrição deve ter pelo menos 200 caracteres",
              {
                title: "Produto",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              }
            );
            if (document.querySelector("#description")) {
              document.querySelector("#description").focus();
            }
            this.submit = false;
            return;
          }

          // pagina de vendas  validação

          if (!this.product.sales_page) {
            this.$bvToast.toast("Página de vendas é obrigatório", {
              title: "Produto",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            if (document.querySelector("#sales_page")) {
              document.querySelector("#sales_page").focus();
            }
            this.submit = false;
            return;
          }

          // validação parcelas de assinatura
          // if (this.product.type == "SUBSCRIPTION" && this.product.period > 30) {
          //   let text_sub = "";
          //   if (!this.product.max_subscription_installments) {
          //     this.product.max_subscription_installments = 1;
          //   }

          //   if (
          //     this.product.period == 90 &&
          //     this.product.max_subscription_installments > 3
          //   ) {
          //     text_sub = "Período trimestral. Parcela máxima 3x";
          //   }
          //   if (
          //     this.product.period == 180 &&
          //     this.product.max_subscription_installments > 6
          //   ) {
          //     text_sub = "Período semestral. Parcela máxima 6x";
          //   }
          //   if (
          //     this.product.period == 365 &&
          //     this.product.max_subscription_installments > 12
          //   ) {
          //     text_sub = "Período anual. Parcela máxima 12x";
          //   }

          //   if (text_sub) {
          //     this.$bvToast.toast(text_sub, {
          //       title: "Produto",
          //       variant: "danger",
          //       autoHideDelay: 5000,
          //       appendToast: true,
          //     });
          //     if (document.querySelector("#subscription_installments")) {
          //       document.querySelector("#subscription_installments").focus();
          //     }
          //     this.submit = false;
          //     text_sub = "";
          //     return;
          //   }
          // } else this.product.max_subscription_installments = 1;

          if (this.product.type == "TRANSACTION") {
            this.product.trial = null;
          }

          if (this.product.period == "CUSTOM") {
            this.product.period = +this.custom_period;
          }

          if (this.product.type == "SUBSCRIPTION" && this.product.charges > 0) {
            this.product.charges = +this.product.charges;
          } else {
            this.product.charges = null;
          }

          // validação afiliados
          if (!this.product.affiliation) {
            this.product.affiliation_approbation = false;
            this.product.affiliation_public = false;
            this.product.affiliation_proposal = false;
          } else {
            if (this.product.comission) {
              this.product.comission = parseFloat(
                String(this.product.comission).replace(",", ".")
              );
            } else {
              this.$bvToast.toast(
                "Comissão deve ser maior que 0 e menor que 99%",
                {
                  title: "Produto",
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
                }
              );
              if (document.querySelector("#comissao")) {
                document.querySelector("#comissao").focus();
              }
              this.submit = false;
              return;
            }

            // parcela comissão
            if (this.product.max_comission == 100) {
              this.product.max_comission = null;
            }
          }

          // parcela fixa
          // this.product.fixed_installments =
          //   this.product.fixed_installments || null;

          // if (this.product.fixed_installments) {
          //   this.product.max_installments = this.product.fixed_installments;
          // }

          // validação parcelas boleto
          // this.product.max_boleto_installments =
          //   +this.product.max_boleto_installments;

          // if (this.permite_parcela_boleto) {
          //   if (
          //     this.product.max_boleto_installments > 12 ||
          //     this.product.max_boleto_installments < 1
          //   ) {
          //     this.$bvToast.toast("Parcelas devem ser min 1x ou max 12x", {
          //       title: "Produto",
          //       variant: "danger",
          //       autoHideDelay: 5000,
          //       appendToast: true,
          //     });
          //     if (document.querySelector("#parcelas_boleto")) {
          //       document.querySelector("#parcelas_boleto").focus();
          //     }
          //     this.submit = false;
          //     return;
          //   }
          // } else this.product.max_boleto_installments = 0;

          // validação trial
          if (!this.product.trial || this.product.trial.length == 0)
            this.product.trial = null;

          //  metodos de pagamento
          // if (Array.isArray(this.product.method)) {
          //   this.product.method = this.product.method.map((item) => {
          //     switch (item.value) {
          //       case "ALL":
          //       default: {
          //         return [
          //           "CREDIT_CARD",
          //           "BOLETO",
          //           "PIX",
          //           "PAYPAL",
          //           "TWO_CREDIT_CARDS",
          //         ];
          //       }
          //       case "CREDIT_CARD": {
          //         return "CREDIT_CARD";
          //       }
          //       case "BOLETO": {
          //         return "BOLETO";
          //       }
          //       case "PIX": {
          //         return "PIX";
          //       }
          //       case "PAYPAL": {
          //         return "PAYPAL";
          //       }
          //       case "TWO_CREDIT_CARDS": {
          //         return "TWO_CREDIT_CARDS";
          //       }
          //     }
          //   });
          // } else {
          //   this.product.method = this.product.method
          //     ? this.product.method.value
          //     : "CREDIT_CARD,PIX,BOLETO,PAYPAL,TWO_CREDIT_CARDS";
          // }
          // this.product.method = this.product.method
          //   ? this.product.method.toString()
          //   : "CREDIT_CARD,PIX,BOLETO,PAYPAL,TWO_CREDIT_CARDS";

          this.product.fiscal_center_setting = this.fiscal_center_setting;
          this.product.category_fiscal = this.category_fiscal;
          this.product.fiscal_code = this.fiscal_code;

          if (this.product.format == "PHYSICALPRODUCT") {
            this.product.is_checkout_address = true;

            if (this.product.has_shipping_fee) {
              this.product.weight = +this.product.weight;
              this.product.width = +this.product.width;
              this.product.height = +this.product.height;
              this.product.length = +this.product.length;
            }

            if (!this.product.has_custom_shipping_address) {
              delete this.product.address_product_id;
            }
          } else {
            this.product.has_shipping_fee = false;
            this.product.weight = 0;
            this.product.width = 0;
            this.product.height = 0;
            this.product.length = 0;
          }

          // pagina de obrigado
          this.product.custom_thank_you_pages = this.custom_thank_you_pages;

          this.product.method = null;

          //Editando produto
          if (typeof this.product.id != "undefined") {
            let product = this.product;
            product.status = "CHANGED";
            serviceProduct
              .update(product)
              .then((response) => {
                this.initCobranca();
                this.$bvToast.toast("Produto salvo com sucesso", {
                  title: "Produto",
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: true,
                });

                // if (response.pending) {
                if (response.status == "REQUESTED") {
                  this.product.status = "REQUESTED";
                  this.$bvToast.toast(
                    "Produto foi enviado para aprovação. Você receberá a resposta da análise por email",
                    {
                      title: "Produto",
                      variant: "info",
                      autoHideDelay: 5000,
                      noAutoHide: true,
                      appendToast: true,
                    }
                  );
                }
              })
              .finally(() => {
                this.submit = false;
                if (this.product.max_comission == null) {
                  this.product.max_comission = 100;
                }
                if (this.custom_period) {
                  this.product.period = "CUSTOM";
                }
              });
          }
        } else {
          this.loading = false;
          this.submit = false;
          this.$bvToast.toast(
            "Campos obrigátorios devem ser preenchidos. Verifique por favor",
            {
              title: "Produto",
              variant: "danger",
              autoHideDelay: 5000,
              noAutoHide: true,
              appendToast: true,
            }
          );
        }
      });
    },

    showConfirmSubscriptionChanged: async function() {
      let result = await Vue.swal({
        title: "Alterar assinatura",
        text:
          "Tem certeza que deseja alterar o valor desse contrato? Todos os clientes inscritos nesse contrato serão afetados.",
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, tenho certeza",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-primary mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      });
      return result.isConfirmed;
    },
    // puxando link de afiliado
    affiliateLink() {
      this.loading_affiliate = true;
      let data = {
        product_id: this.id_product,
        url: "invite",
      };

      serviceAffiliate
        .search(data)
        .then((response) => {
          this.link_afiliado = `https://adm.voompcreators.com.br/recrutamento/${response.hash}/?p_id=${this.id_product}`;
          this.loading_affiliate = false;
        })
        .catch((err) => {
          this.loading_affiliate = false;
        });
    },

    // ações inputFile Imagem produto
    uploadImageSuccess(formData, index, fileList) {
      this.product.images = fileList;
    },
    beforeRemove(index, done, fileList) {
      var r = confirm("Remover imagem?");
      if (r == true) {
        done();
        this.product.images.splice(index, 1);
      }
    },
    // editImage(formData, index, fileList) {
    //   this.product.images = fileList;
    // },

    // ações inputFile banner
    uploadImageSuccessBannerCheckout(formData, index, fileList) {
      this.product.banner_checkout = fileList;
    },
    beforeRemoveBannerCheckout(index, done, fileList) {
      var r = confirm("Remover imagem?");
      if (r == true) {
        done();
      }
    },

    // Puxando categorias
    fetchCategories() {
      this.categories = [
        { value: null, text: "Selecione a categoria", disabled: true },
      ];

      serviceCategory.search().then((response) => {
        response.forEach((element) => {
          this.categories.push({ value: element.id, text: element.name });
        });
      });
    },

    // carregando o produto
    loadProduct() {
      this.loading = true;
      this.submit = true;
      if (this.id_product) {
        let data = {
          id: `adm/${this.id_product}`,
        };

        serviceProduct
          .read(data)
          .then((response) => {
            this.error_get_product = false;
            this.product = response.data;

            this.product.max_subscription_installments =
              this.product.max_subscription_installments || null;

            if (this.product.period) {
              this.changeParcelasSub(this.product.period, true);
            }

            if (this.product.type == "SUBSCRIPTION") {
              this.initSmartSale();

              this.methods = [
                { name: "Cartão de crédito", value: "CREDIT_CARD" },
                { name: "Boleto", value: "BOLETO" },
              ];

              // validanod period
              if (
                this.permite_periodo_personalizado &&
                this.product.period != 30 &&
                this.product.period != 90 &&
                this.product.period != 180 &&
                this.product.period != 365
              ) {
                this.custom_period = this.product.period;
                this.product.period = "CUSTOM";
              }
            } else if (this.product.type == "TRANSACTION") {
              this.checkMethodTransaction();
              this.initSmartSale();
            }

            // link do checkout
            this.link_checkout = this.baseURL + "/" + this.product.id;

            // link pagina de vendas
            this.product.sales_page = this.product.links.length
              ? this.product.links[0].url
              : "";

            // convertendo afiliados
            this.product.affiliation =
              this.product.affiliation == 1 ? true : false;
            this.product.affiliation_public =
              this.product.affiliation_public == 1 ? true : false;
            this.product.affiliation_approbation =
              this.product.affiliation_approbation == 1 ? true : false;
            this.product.allow_proposal =
              this.product.allow_proposal == 1 ? true : false;

            // cupom de desconto
            this.product.allowed_coupon =
              this.product.allowed_coupon == 1 ? true : false;

            // juros para o vendedor
            this.product.no_interest_installments =
              this.product.no_interest_installments == 1 ? true : false;

            // venda inteligente
            this.product.smart_sale =
              this.product.smart_sale == 1 ? true : false;

            this.product.smart_sale_dynamic_installments =
              this.product.smart_sale_dynamic_installments == 1 ? true : false;

            // endereço checkout
            this.product.is_checkout_address =
              this.product.is_checkout_address == 1 ? true : false;

            // produto fisico
            this.product.has_stock = this.product.has_stock == 1 ? true : false;

            this.product.warranty_checkout =
              this.product.warranty_checkout == 1 ? true : false;

            this.product.can_be_gifted =
              this.product.can_be_gifted == 1 ? true : false;

            this.product.has_shipping_fee =
              this.product.has_shipping_fee == 1 ? true : false;

            this.product.has_custom_shipping_address =
              this.product.has_custom_shipping_address == 1 ? true : false;

            // parcelamento de boleto
            this.permite_parcela_boleto = this.product.max_boleto_installments
              ? true
              : false;

            // comissao parcela
            if (!this.product.max_comission) {
              this.product.max_comission = 100;
            }

            // imagem banner
            this.product.banner_checkout =
              this.product.banner_checkout &&
              this.product.banner_checkout.indexOf("https://") > -1
                ? [
                    {
                      path: this.product.banner_checkout,
                    },
                  ]
                : [];

            // parcelamento de Cartao
            this.product.max_installments = this.product.max_installments || 12;

            // adicionando coprodutores
            this.co_sellers_list = this.product.co_sellers;

            var co_sellers_list = this.co_sellers_list;
            var co_sellers_list2 = [];
            co_sellers_list.forEach((element) => {
              co_sellers_list2.push({
                value: element.user.id,
                text: element.user.name,
              });
            });
            var optionsCoProdutor = this.optionsCoProdutor;
            var result = optionsCoProdutor.concat(co_sellers_list2);
            this.optionsCoProdutor = result;
            if (response.data.co_seller_display) {
              this.product.co_seller_display_id =
                response.data.co_seller_display.id;
            }

            // adicionando arquivos na lista
            this.files_list = this.product.attachments || [];

            this.category_fiscal = this.product.category_fiscal;
            this.fiscal_code = this.product.fiscal_code;

            // Configuração da central fiscal
            if (
              this.product.fiscal_center_setting &&
              this.product.fiscal_center_setting.length
            ) {
              this.product.fiscal_center_setting.forEach((item) => {
                switch (item.key) {
                  case "GENERATE_AFTER_WARRANTY":
                    this.fiscal_center_setting.generate_after_warranty =
                      item.value === 1;
                    break;
                  case "CO_PRODUCER_WILL_ISSUE_NOTES":
                    this.fiscal_center_setting.co_producer_will_issue_note =
                      item.value === 1;
                    break;
                }
              });
            }

            if (!this.product.custom_thank_you_pages) {
              this.custom_thank_you_pages = [];
            } else {
              this.custom_thank_you_pages = this.product.custom_thank_you_pages;
            }
          })
          .catch(() => {
            this.error_get_product = true;
          })
          .finally(() => {
            // fim
            this.loading = false;
            this.submit = false;
            if (this.product.type === "TRANSACTION")
              this.customPreInstallmentsOptions();
            if (this.product.type === "SUBSCRIPTION")
              this.customPreInstallmentsOptionsSubscription();
            if (
              this.product.max_subscription_installments &&
              this.product.type === "SUBSCRIPTION"
            ) {
              this.validate_pre_selected = false;
              this.customPreInstallmentsOptionsSubscriptionAss();
            }
          });
      }
    },

    customPreInstallmentsOptions() {
      if (this.validate_pre_selected_two) {
        this.product.pre_selected_installment = null;
      }
      this.validate_pre_selected_two = true;
      let options = [
        { value: null, text: "Selecione uma parcela", disabled: true },
        { value: 12, text: "Parcela 12x" },
        { value: 11, text: "Parcela 11x" },
        { value: 10, text: "Parcela 10x" },
        { value: 9, text: "Parcela 9x" },
        { value: 8, text: "Parcela 8x" },
        { value: 7, text: "Parcela 7x" },
        { value: 6, text: "Parcela 6x" },
        { value: 5, text: "Parcela 5x" },
        { value: 4, text: "Parcela 4x" },
        { value: 3, text: "Parcela 3x" },
        { value: 2, text: "Parcela 2x" },
        { value: 1, text: "Parcela 1x" },
        { value: 0, text: "Sem parcelas" },
      ];
      if (this.product.max_installments != null) {
        let newOptions = [];
        newOptions = options.filter((p) => {
          return p.value <= this.product.max_installments || p.value == null
            ? true
            : false;
        });
        this.option_pre_selected_installment = newOptions;
      } else {
        this.option_pre_selected_installment = options;
      }
    },

    customPreInstallmentsOptionsSubscription() {
      let options = [
        { value: null, text: "Selecione uma parcela", disabled: true },
        { value: 12, text: "Parcela 12x" },
        { value: 11, text: "Parcela 11x" },
        { value: 10, text: "Parcela 10x" },
        { value: 9, text: "Parcela 9x" },
        { value: 8, text: "Parcela 8x" },
        { value: 7, text: "Parcela 7x" },
        { value: 6, text: "Parcela 6x" },
        { value: 5, text: "Parcela 5x" },
        { value: 4, text: "Parcela 4x" },
        { value: 3, text: "Parcela 3x" },
        { value: 2, text: "Parcela 2x" },
        { value: 1, text: "Parcela 1x" },
        { value: 0, text: "Sem parcelas" },
      ];
      if (this.product.period != null) {
        let newOptions = [];

        if (this.product.period === 90) {
          newOptions = options.filter((o) => {
            return o.value <= 3 || o.value == null ? true : false;
          });
        } else if (this.product.period === 180) {
          newOptions = options.filter((o) => {
            return o.value <= 6 || o.value == null ? true : false;
          });
        } else if (this.product.period === 365) {
          newOptions = options.filter((o) => {
            return o.value <= 12 || o.value == null ? true : false;
          });
        }
        this.option_pre_selected_installment = newOptions;
      } else {
        this.option_pre_selected_installment = options;
      }
    },

    customPreInstallmentsOptionsSubscriptionAss() {
      if (this.validate_pre_selected) {
        this.product.pre_selected_installment = null;
      }
      this.validate_pre_selected = true;
      let options = [
        { value: null, text: "Selecione uma parcela", disabled: true },
        { value: 12, text: "Parcela 12x" },
        { value: 11, text: "Parcela 11x" },
        { value: 10, text: "Parcela 10x" },
        { value: 9, text: "Parcela 9x" },
        { value: 8, text: "Parcela 8x" },
        { value: 7, text: "Parcela 7x" },
        { value: 6, text: "Parcela 6x" },
        { value: 5, text: "Parcela 5x" },
        { value: 4, text: "Parcela 4x" },
        { value: 3, text: "Parcela 3x" },
        { value: 2, text: "Parcela 2x" },
        { value: 1, text: "Parcela 1x" },
        { value: 0, text: "Sem parcelas" },
      ];
      if (this.product.max_subscription_installments != null) {
        let newOptions = [];
        newOptions = options.filter((p) => {
          return p.value <= this.product.max_subscription_installments ||
            p.value == null
            ? true
            : false;
        });
        this.option_pre_selected_installment = newOptions;
      } else {
        this.option_pre_selected_installment = options;
      }
    },

    changeFixedInstallments() {
      this.product.pre_selected_installment = null;
      if (this.product.type === "TRANSACTION")
        this.customPreInstallmentsOptions();
      if (
        this.product.type === "SUBSCRIPTION" &&
        this.product.max_subscription_installments
      ) {
        this.customPreInstallmentsOptionsSubscriptionAss();
      } else if (this.product.type === "SUBSCRIPTION")
        this.customPreInstallmentsOptionsSubscription();
    },
  },
  mounted() {
    this.loading = true;
    this.fetchUserSettings();
    EventBus.$on("integracoesLista", (data) => {
      this.existe_integracao += data;
    });

    EventBus.$on("textEditMarkDown", (data) => {
      if (this.type_open_md == "descricao") {
        this.product.description = data;
      } else if (this.type_open_md == "instrucoes") {
        this.product.instructions = data;
      }
      this.onSubmit();

      this.type_open_md = "";
    });

    document.querySelector("body").style = "overflow: auto !important; ";

    this.loadProduct();

    this.fetchCategories();

    this.client.width = window.innerWidth;
  },
  created() {
    if (typeof this.$route.params.id_product != "undefined") {
      this.id_product = this.$route.params.id_product;
    }
    if (this.$route.params.code !== undefined) {
      infusionsoftService
        .create(
          {
            code: this.$route.query.code,
            // redirect_uri: `http://localhost:8083${this.$route.fullPath.split('?')[0]}`
            // redirect_uri: `https://victoradm.innovaweb.com.br${this.$route.fullPath.split('?')[0]}`
            redirect_uri: `${process.env.VUE_ADM_PAGE}${
              this.$route.fullPath.split("?")[0]
            }`,
          },
          null,
          "/token"
        )
        .then((res) => {
          let data = res;
          data.title = this.$route.params.code;
          data.type = "infusionsoft";
          data.expires_in = `${data.expires_in}`;
          data.code = this.$route.query.code;
          connectionService.create(data).then(() => {
            this.$bvToast.toast(`A nova conexão com Infusion Soft foi criada`, {
              title: "Infusion Soft",
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.initIntegracoes();
            this.$bvModal.show("modal-infusionsoft");
            let linkToReplace = `${this.$route.path}`.replace(
              `/${this.$route.params.code}`,
              ""
            );
            this.$router.push(linkToReplace);
          });
        })
        .catch((e) => {
          this.$bvToast.toast(`${e.scope ? e.scope[0] : ""}`, {
            title: "Infusion Soft",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
          setTimeout(() => {
            let linkToReplace = `${this.$route.path}`.replace(
              `/${this.$route.params.code}`,
              ""
            );
            this.$router.push(linkToReplace);
          }, 300);
        });
    }
  },
};
</script>

<style>
.tab-advanced > .nav-item > .nav-link {
  color: #333;
  font-weight: 500;
  font-size: 14px;
}

.tab-advanced > .nav-item > .active {
  color: #091e3e !important;
  font-weight: 600 !important;
}
</style>

<style lang="scss" scoped>
p {
  font-size: 15px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
.img-produto {
  margin-bottom: 40px;
}
.btn-green {
  display: inline-block;
  border-radius: 5px;
  padding: 7px 20px;
  border: 1px $base-color solid;
  width: auto;
  color: #fff;
  background: $base-color;
  cursor: pointer;

  &:hover {
    background: #fff;
    color: $base-color;
    border: 1px $base-color solid;
  }
}

.container-form {
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 20px;
}
@media screen and (max-width: 768px) {
  .preco input {
    width: 100%;
  }
  .container-form {
    display: block;
  }
}
.input-upload {
  margin-top: 20px;
}
.form-produto {
  width: 100%;
}
.footer-edit {
  padding: 30px 15px 40px 15px;
  border-top: 1px solid #ededf0;
  margin-top: 40px;
  transition: 0.3s;
}
.footer-flex {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.links-uteis {
  display: block;
}
@media screen and (max-width: 768px) {
  .footer-flex {
    display: block;
  }
  .links-uteis {
    margin-top: 15px;
  }
  .botoes {
    margin-top: 30px;
    text-align: right;
  }
}
.links-uteis p {
  font-size: 12px;
  max-width: 100%;
}
.links-uteis a {
  font-size: 12px;
  color: #2a63ab;
  max-width: 100%;
}
.carregando {
  background: rgba(0, 0, 0, 0.03);
  color: transparent !important;
  border-radius: 4px;
  pointer-events: none;
}
.container-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.container-integracoes {
  grid-template-columns: 1fr 250px;
  gap: 40px;
}
.flex-row {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .flex-row {
    display: block !important;
  }
}

.grid-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
@media screen and (max-width: 768px) {
  .grid-row {
    display: block;
  }
}
.grid-avancado {
  gap: 40px;
}
//
// / lista de entregas
.lista-entrega h5,
h5 {
  margin-bottom: 20px;
  color: $black;
  font-weight: 600;
  font-size: 14px;
}
.lista-entrega ul,
.opcoes-entrega {
  display: flex;
  flex-direction: column;
}
.lista-entrega ul li,
.opcoes-entrega li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 30px;
  height: 70px;
  border-radius: 5px;
  background: rgba(247, 247, 247, 0.5);
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s;
}
.lista-arquivos ul li {
  padding: 13px 20px;
  font-size: 13px;
}
.opcoes-entrega li {
  padding: 20px 30px;
  height: 80px;
}
.lista-entrega ul li div {
  display: flex;
  align-items: center;
  font-size: 15px;
  text-transform: capitalize;
}
.opcoes-entrega li .grid-content {
  display: grid;
  grid-template-columns: 120px 1fr;
  align-items: center;
}
.lista-entrega ul li div img {
  width: 14px;
  margin-right: 30px;
}
.opcoes-entrega li div img {
  width: 20px;
  margin-right: 30px;
  filter: invert(50%);
}

.item-upload {
  position: relative;
  padding: 40px;
  border: 2px dashed #ededf0;
  border-radius: 5px;
  text-align: center;
}
.item-upload:hover {
  background: #f7f7f7;
}
.item-upload h5 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}
.item-upload p {
  font-size: 13px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
#arquivo-entrega {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  height: 80px;
  cursor: pointer;
  opacity: 0;
}
.opcoes-entrega li .infos h5,
.grid-content h5 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}
.grid-content h5 {
  margin: 0;
}
.opcoes-entrega li .infos p {
  font-size: 13px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
.opcoes-entrega li div img.logo-entrega {
  width: 60px;
}
.lista-entrega ul {
  max-height: 400px;
  overflow: auto;
}
.lista-arquivos ul {
  max-height: initial;
  overflow: initial;
}
.icon-lixeira,
.icon-editar {
  width: 14px;
  filter: invert(50%);
  cursor: pointer;
}
.icon-editar {
  margin-right: 15px !important;
}
.opcoes-entrega li:hover {
  font-weight: 600;
  background: #f7f7f7;
}

.icon-lixeira:hover,
.icon-editar:hover {
  filter: invert(0) !important;
}
.opcoes-entrega li + li {
  // border-top: 1px solid #ededf0;
  margin-top: 10px;
}

.opcoes-entrega li.greenn-club {
  background: rgba(0, 33, 99, 0.1);
  position: relative;
}
.opcoes-entrega li.greenn-club div h5 {
  color: #002363;
}
.opcoes-entrega li.greenn-club div img {
  filter: invert(0);
}
.opcoes-entrega li.greenn-club:hover {
  background: #002363;
}
.opcoes-entrega li.greenn-club:hover h5,
.opcoes-entrega li.greenn-club:hover p {
  color: #fff;
}
.opcoes-entrega li.greenn-club:hover div img {
  filter: brightness(0) invert(1);
}

// outras opcoes
.opcoes-entrega li.ativo {
  background: rgba(33, 51, 210, 0.2);
}
.opcoes-entrega li.ativo div h5,
.opcoes-entrega li.ativo div p {
  color: #2a63ab;
}
.opcoes-entrega li.ativo div img {
  filter: brightness(0) invert(1);
}
.outras-opcoes {
  margin: 10px 0;
  display: none;
  animation: showTop 0.3s forwards;
}
.outras-opcoes.ativo {
  display: block;
}
.voltar-opcoes {
  filter: none !important;
}
.nova-entrega {
  padding: 20px 0;
  padding-top: 40px;
}
@media screen and (max-width: 768px) {
  .nova-entrega button,
  #metas {
    width: 100%;
  }
}
img.icon-file {
  width: 20px !important;
}
// co-seller
.co-seller-item {
  width: 90%;
}
.info-co-seller {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.info-co-seller h5 {
  margin: 0;
}
.info-co-seller .separacao-co-seller {
  display: block;
}
.info-co-seller .separacao-co-seller:last-child {
  text-align: right;
}
.info-co-seller .separacao-co-seller h5 {
  color: $black;
}
.info-co-seller .separacao-co-seller p {
  margin-top: 5px;
  // color: #81858e;
}
.title-inter {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #2a63ab;
}
.form-group + .form-group {
  margin-top: 15px;
}
.link-documentacao {
  padding: 0 25px !important;
  height: 45px !important;
  font-size: 13px;
}

.footer-flex {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.links-uteis {
  display: block;
}
@media screen and (max-width: 768px) {
  .footer-flex {
    display: block;
  }
  .links-uteis {
    margin-top: 10px;
  }
  .botoes {
    margin-top: 30px;
    text-align: right;
  }
}
.links-uteis p {
  font-size: 12px;
}
.links-uteis a {
  font-size: 12px;
  color: #2a63ab;
}
.carregando {
  background: rgba(0, 0, 0, 0.03);
  color: transparent !important;
  border-radius: 4px;
  pointer-events: none;
}
.sub-dominio {
  font-weight: normal;
  color: #81858e;
  font-size: 13px;
  line-height: 1.5;
  margin-top: 5px;
}
.check-box {
  margin-left: 40px;
}
.check-box .info-checkbox {
  font-size: 13px;
  font-weight: 500;
  margin-left: 5px;
  cursor: pointer;
  max-width: 250px;
}
.grid-events {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 20px 0;
}
.info-list {
  position: relative;
}
.info-list p.ativo {
  text-transform: lowercase;
  font-size: 12px;
  margin-top: 3px;
  color: #81858e;
  display: block;
}
.info-list p {
  display: none;
}

.input-edit-webhook {
  height: 20px !important;
  width: 450px;
  padding: 5px 0 15px 0 !important;
  font-size: 12px !important;
  margin-bottom: -2px !important;
  margin-top: 10px !important;
  background: transparent;
  border: none !important;
  border-bottom: 1px solid #81858e !important;
  border-radius: 0 !important;
  display: none;
}
@media screen and (max-width: 768px) {
  .input-edit-webhook {
    width: 250px;
  }
}
.input-edit-webhook.ativo {
  display: block;
}
.btn-edit-web {
  position: absolute;
  bottom: 27px;
  right: 0;
  height: 25px !important;
  padding: 0 10px !important;
  font-size: 11px !important;
  display: none;
}
.btn-edit-web.ativo {
  display: block;
}
.checkbox-cursor,
.info-checkbox {
  cursor: pointer;
}

// pixel
.container-pixels {
  max-height: 500px;
  overflow: auto;
  padding: 10px;
}
.pixel {
  padding: 15px;
  border: 1px solid #ededf0;
  border-radius: 5px;
}
.pixel + .pixel {
  margin-top: 10px;
}
.pixel .iconePixel {
  margin: 15px 0 20px 0;
  max-width: 170px;
}
.pixel span {
  font-size: 14px;
  font-weight: 600;
  display: block;
  margin-bottom: 15px;
}
.pixel div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pixel div.pixel-type {
  display: block;
}
.pixel-status {
  flex-wrap: wrap;
  justify-content: flex-start !important;
  gap: 10px;
}
.status-pixel {
  font-size: 11px !important;
  font-weight: normal !important;
  background: #f7f7f7;
  color: #000;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 0 !important;
}
.status-pixel.ativada {
  background: #00236320;
  color: #002363;
}
.pixel p {
  font-size: 13px;
}
input#pixel_id,
select#type_pixel,
.btn-pixel {
  padding: 0 20px !important;
  height: 40px !important;
  font-size: 12px;
}
.pixel h6 {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  font-size: 15px;
  margin: 10px 0;
  color: #2a63ab;
}
.pixel h6 img {
  filter: invert(50%);
}

// aprovação
.btn-2 {
  color: #2a63ab;
  background: rgba(33, 51, 210, 0.1);
  font-size: 12px;
  font-weight: 600;
  padding: 10px 15px;
  border-radius: 5px;
}
.aprovacao {
  display: flex;
  align-items: center;
}
.aprovacao img {
  margin-right: 10px;
}
.top-infos {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 768px) {
  .top-infos {
    display: block;
  }
  .aprovacao {
    margin: 20px 0 30px 0;
    // max-width: 180px;
  }
}

.btn-club {
  width: 247px;
}

#description,
#instructions {
  height: 20vh !important;
  resize: none;
  font-size: 13px !important;
}
.mark-down {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.info-mark {
  margin: 0;
  font-weight: normal;
  color: #2a63ab;
  font-size: 13px;
  line-height: 1.5;
}
.open-mark {
  color: #2a63ab;
  background: rgb(255, 255, 255);
}
.recomendado {
  width: 120px;
  margin-top: -40px;
}
.recomendado p {
  margin: 0;
  color: #81858e;
  font-size: 13px;
}
.recomendado p + p {
  margin-top: 10px;
}
.links-rapidos a {
  display: block;
  width: 200px;
  font-size: 14px;
  color: #2a63ab;
}
.links-rapidos a + a {
  margin-top: 10px;
}
.descricao-container {
  position: relative;
}
.min-descricao {
  min-width: 30px;
  min-height: 30px;
  padding: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff0c37;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  top: -13px;
  right: 0;
}
.min-descricao.ok {
  color: $black;
  // background: rgba(33, 51, 210, 0.2);
}
.teste-tiktok {
  color: #2a63ab;
  font-weight: 600;
  font-size: 13px;
}
.badge-success {
  padding: 10px 15px;
  font-weight: 600;
  color: #2a63ab;
  background: rgba(33, 51, 210, 0.15);
  white-space: pre-line;
  line-height: 1.5;
  text-align: left;
}
.nova-pagina {
  margin-left: 20px;
  color: #2a63ab;
  font-size: 13px;
}

.remove-page a {
  display: block;
  margin-top: 35px !important;
  margin-left: 10px;
  font-weight: 600;
  color: #ff0c37;
  font-size: 13px;
}
.remove-page a:hover {
  text-decoration: none;
}
.item-page {
  margin-top: 15px;
}

@media screen and (max-width: 768px) {
  .nova-pagina {
    display: none;
  }
  .item-page {
    border: 1px solid #ededf0;
    padding: 15px 20px;
    border-radius: 5px;
  }
  .item-page.item-page .remove-page {
    margin-left: auto !important;
  }
  .item-page.item-page .remove-page a {
    margin: 0 !important;
    margin-top: 10px !important;
  }
}
</style>
