<template>
  <BaseModal
    name="modal-memberkit"
    size="lg"
    title="Adicione a entrega via Memberkit"
    @shown="openModal"
  >
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <section class="container-arquivos" v-else>
      <div class="lista-entrega lista-arquivos mt-4">
        <div>
          <h4 class="title-inter">• MemberKit</h4>
          <div class="d-flex mb-4">
            <BaseButton
              variant="info3"
              class="gerencia-conexoes"
              @click="openConexoes"
              >Gerenciar conexões</BaseButton
            >
          </div>
          <b-form
            data-vv-scope="memberkit"
            v-if="memberkit.options_conexao.length"
          >
            <b-row>
              <b-col class="mr-3">
                <b-form-group label="Conexão" label-for="conexao">
                  <b-form-select
                    v-model="memberkit.data.connection_id"
                    :options="memberkit.options_conexao"
                    name="conexao"
                    id="conexao"
                    placeholder="Selecione a Conexão"
                    v-validate="'required'"
                    @change="changeConnection"
                  >
                  </b-form-select>
                  <b-form-invalid-feedback
                    :state="!errors.has('memberkit.conexao')"
                  >
                    Selecione a conexão
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col>
                <b-form-group label="Tipo" label-for="tipo">
                  <b-form-select
                    v-model="memberkit.data.access"
                    :options="memberkit.options_conexao_tipo"
                    name="tipo"
                    id="tipo"
                    placeholder="Selecione a Conexão"
                    v-validate="'required'"
                    @change="changeMemberKit"
                  >
                  </b-form-select>
                  <b-form-invalid-feedback
                    :state="!errors.has('memberkit.tipo')"
                  >
                    Selecione um Tipo
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>

            <b-form-group
              label="Selecione a Turma/Curso"
              label-for="classroom"
              v-if="
                this.memberkit.data.access == 'classroom' &&
                  this.memberkit.options.length
              "
              data-anima="top"
            >
              <multiselect
                v-model="memberkit.data.classroom_ids"
                :options="memberkit.options"
                :value="memberkit.data.classroom_ids"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Selecione a Turma"
                label="text"
                track-by="text"
                selectLabel=""
                deselectLabel=""
                selectedLabel="✔️"
                class="multi_select_memberkit"
                id="multi_select_memberkit"
                name="classroom"
                v-validate="'required'"
              >
              </multiselect>
              <b-form-invalid-feedback
                :state="!errors.has('memberkit.classroom')"
              >
                Nenhuma Turma/Curso selecionada
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              label="Selecione a Assinatura"
              label-for="membership_id"
              v-if="
                this.memberkit.data.access == 'subscription' &&
                  this.memberkit.options.length
              "
              data-anima="top"
            >
              <b-form-select
                v-model="memberkit.data.membership_id"
                :options="memberkit.options"
                name="membership_id"
                id="membership_id"
                placeholder="Selecione a Assinatura"
                v-validate="'required'"
              >
              </b-form-select>
              <b-form-invalid-feedback
                :state="!errors.has('memberkit.data.membership_id')"
              >
                Nenhuma assinatura selecionada
              </b-form-invalid-feedback>
            </b-form-group>
          </b-form>
          <div v-else>
            <p>
              Verificamos que não há conexões com o Memberkit vinculadas em sua
              conta.
            </p>
            <p>
              Por gentileza ajuste a conexão para seguir o fluxo e adicionar uma
              integração com o Memberkit.
            </p>
            <br />
            <p>Clique no botão acima para gerenciar suas conexões</p>
          </div>
        </div>
      </div>
    </section>

    <!-- Modal conexoes -->
    <Conexoes @close="fetchConnections" />

    <template v-slot:footer="{ cancel }">
      <BaseButton
        :disabled="loading"
        variant="link-info"
        class="mr-4"
        @click="cancel"
      >
        Cancelar
      </BaseButton>
      <BaseButton
        v-if="memberkit.options_conexao.length"
        variant="info3"
        :disabled="loading || !memberkit.options.length"
        @click="adicionarMemberKit"
      >
        Criar Liberação
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import Multiselect from "vue-multiselect";
import Conexoes from "../../MyAccount/modals/Conexoes";

// services:
import ConnectionService from "@/services/resources/ConnectionService";
const serviceConnection = ConnectionService.build();

import IntegrationService from "@/services/resources/IntegrationService";
const serviceIntegration = IntegrationService.build();

export default {
  data() {
    return {
      loading: false,
      submit: false,
      memberkit: {
        data: {
          access: null,
          type: "memberkit",
          product_id: null,
          connection_id: null,
        },
        options_conexao_tipo: [
          { value: "subscription", text: "Assinatura" },
          { value: "classroom", text: "Turmas" },
        ],
        options_conexao: [],
        options: [],
      },
    };
  },
  props: {
    id_product: {
      type: Number,
    },
  },
  components: {
    Multiselect,
    Conexoes,
  },
  methods: {
    // abrir modal de conexoes
    openConexoes() {
      this.$bvModal.show("modal-conexoes");
    },
    // adicionar um novo item no memberkit
    adicionarMemberKit() {
      this.submit = true;
      this.loading = true;
      this.memberkit.data.product_id = this.id_product;
      // verificando se é assinatura
      if (this.memberkit.data.access == "subscription") {
        this.memberkit.data.membership_name = document.querySelector(
          "#membership_id"
        ).innerText;
      }
      // caso nao seja assinatura
      else {
        this.memberkit.data.classroom_names = [
          ...this.memberkit.data.classroom_ids,
        ].map((item) => {
          return item.text;
        });
        this.memberkit.data.classroom_ids = [
          ...this.memberkit.data.classroom_ids,
        ].map((item) => {
          return item.value;
        });
      }

      this.$validator.validateAll("memberkit").then((result) => {
        if (result) {
          serviceIntegration
            .create(this.memberkit.data)
            .then((response) => {
              if (response.success) {
                this.$bvToast.toast("Regra de liberação criada com sucesso", {
                  title: "MemberKit",
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
                // fechando modal
                this.$bvModal.hide("modal-memberkit");
                //emitindo evento para o componente pai (opcoesEntrega)
                this.$emit("close");
                // limpando campos
                this.changeConnection();
              } else {
                this.$bvToast.toast(
                  "Houve algum erro ao criar a Regra de liberação",
                  {
                    title: "MemberKit",
                    variant: "danger",
                    autoHideDelay: 5000,
                    appendToast: true,
                  }
                );
              }
              this.loading = false;
              this.submit = false;
              // this.$bvModal.hide("modal-integracoes");
            })
            .catch((error) => {
              console.log(error);
              this.loading = false;
              this.submit = false;
            });
        }
      });
    },

    // limpa campos
    changeConnection() {
      this.loading = true;

      this.memberkit.data.access = null;
      this.memberkit.options = [];
      this.memberkit.data.classroom_ids = [];
      this.memberkit.data.membership_id = null;

      this.memberkit.data.access = "classroom";
      this.changeMemberKit(this.memberkit.data.access, true);

      setTimeout(() => {
        this.memberkit.data.access = "subscription";
        this.changeMemberKit();
      }, 1000);
    },

    // evento de change para ajustar o obj memberkit.data de acordo com cada tipo
    changeMemberKit(e, no_search = false) {
      if (this.memberkit.data.access == "subscription") {
        delete this.memberkit.data.classroom_ids;
      } else if (this.memberkit.data.access == "classroom") {
        delete this.memberkit.data.membership_id;
      }

      if (!no_search) {
        this.fetchIntegrationMemberKit();
      }
    },

    // puxa todas as integrações do memberkit e atribui
    fetchIntegrationMemberKit() {
      this.loading = true;
      let data = {
        type: "memberkit",
        access: this.memberkit.data.access,
        connection_id: this.memberkit.data.connection_id,
      };

      serviceIntegration
        .search(data)
        .then((response) => {
          this.memberkit.options = [];

          if (response.length) {
            if (this.memberkit.data.access == "subscription") {
              response.forEach((item) => {
                this.memberkit.options.push({
                  value: item.id,
                  text: item.name,
                });
              });
            } else {
              response.forEach((item) => {
                this.memberkit.options.push({
                  value: item.id,
                  text: `${item.course_name} | ${item.name}`,
                });
              });
            }
          } else {
            this.memberkit.options = [];
            this.$bvToast.toast(
              "Não encontramos dados cadastrados no Memberkit. Por gentileza verifique",
              {
                title: "MemberKit",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              }
            );
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // funcao para puxar as conexoes e atribuir
    fetchConnections() {
      serviceConnection
        .search()
        .then((response) => {
          [...response].filter((item) => {
            if (item.type == "memberkit") {
              this.memberkit.options_conexao.push({
                value: item.id,
                text: item.title,
              });
            }
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // abrindo modal e setando valores
    openModal() {
      this.loading = true;
      // puxando as conexões do memberkit
      this.fetchConnections();
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
.title-inter {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #2a63ab;
}
.form-group + .form-group {
  margin-top: 15px;
}
.gerencia-conexoes {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}
</style>
