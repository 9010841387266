<template>
  <BaseModal
    name="modal-integracoes_club"
    size="lg"
    title="Adicione a entrega pelo Voomp Play"
    @shown="openModal"
    @hidden="clearFields"
  >
    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <section class="container-arquivos" v-else>
      <div class="lista-entrega lista-arquivos mt-4">
        <!-- Voomp Play -->
        <div>
          <div class="d-flex justify-content-between">
            <h4 class="title-inter">• Voomp Play</h4>
            <div v-if="userOk" class="action-club">
              <BaseButton
                variant="secondary_outline"
                class="novo-projeto mb-2"
                @click="toggleNewClub"
                >{{ newClub.flag_name }}</BaseButton
              >
              <BaseButton
                variant="secondary_outline"
                class="btn-entrega button-info3 novo-projeto"
                @click="loginClub"
                v-if="!newClub.flag"
              >
                Entrar no Voomp Play
              </BaseButton>
            </div>
          </div>
          <b-form data-vv-scope="club" v-if="userOk">
            <b-row>
              <b-col cols="12" md="6" v-if="newClub.flag" data-anima="top">
                <b-form-group
                  label="Nome da Área de Membros"
                  label-for="project_name"
                >
                  <b-form-input
                    v-model="newClub.name"
                    name="project_name"
                    id="project_name"
                    placeholder="Digite o nome da Área de Membros"
                    v-validate="'required'"
                    autocomplete="off"
                  />
                  <b-form-invalid-feedback
                    :state="!errors.has('club.project_name')"
                  >
                    Nome da Área de Membros é obrigatório
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group label="Sub Domínio" label-for="subdomain">
                  <b-form-input
                    v-model="newClub.subdomain"
                    name="subdomain"
                    id="subdomain"
                    autocomplete="off"
                    placeholder="Insira um sub domínio"
                    v-validate="{
                      required: true,
                      regex: /^[a-z\-_0-9]{6,30}$/i,
                    }"
                  />
                  <p class="sub-dominio">
                    {{ newClub.subdomain }}.voompplay.com.br
                  </p>
                  <b-form-invalid-feedback
                    :state="!errors.has('club.subdomain')"
                  >
                    Sub Domínio com formato inválido
                  </b-form-invalid-feedback>
                </b-form-group>

                <div class="mt-4">
                  <b-form-group
                    label="Criar demonstração"
                    label-for="create_demo"
                  >
                    <b-form-checkbox
                      v-model="newClub.create_demo"
                      name="create_demo "
                      size="lg"
                      switch
                    >
                      <p class="info-checkbox">
                        Dados de demonstração
                        <span class="descricao-checkbox"
                          >Será criado dados demonstrativos no Club para
                          facilitar o gerenciamento da sua área de membros</span
                        >
                      </p>
                    </b-form-checkbox>
                  </b-form-group>
                </div>
              </b-col>

              <b-col cols="12" md="6" data-anima="top" v-if="!newClub.flag">
                <b-form-group
                  label="Selecione a área de membros"
                  label-for="projeto"
                >
                  <b-form-select
                    v-model="club.site_id"
                    :options="club.options_projetos"
                    name="projeto"
                    id="projeto"
                    placeholder="Selecione a área de membros"
                    v-validate="'required'"
                    @change="changeProjeto"
                  >
                  </b-form-select>
                  <b-form-invalid-feedback :state="!errors.has('club.projeto')">
                    Selecione uma área de membros
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label="Tipo"
                  label-for="tipo"
                  v-show="club.site_id"
                >
                  <b-form-select
                    v-model="club.data.access"
                    :options="club.options_conexao_tipo"
                    name="tipo"
                    id="tipo"
                    placeholder="Selecione a Conexão"
                    v-validate="'required'"
                    @change="changeClub"
                  >
                  </b-form-select>
                  <b-form-invalid-feedback :state="!errors.has('club.tipo')">
                    Selecione um Tipo
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <div v-if="!newClub.flag && club.data.access">
              <div v-if="club.options.length">
                <b-form-group
                  label="Selecione a Turma/Curso"
                  label-for="classroom"
                  v-if="club.data.access == 'course_class'"
                  data-anima="top"
                >
                  <multiselect
                    v-model="club.data.course_class_id"
                    :options="club.options"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder="Selecione a Turma"
                    label="text"
                    track-by="text"
                    selectLabel=""
                    deselectLabel=""
                    selectedLabel="✔️"
                    id="classroom"
                    name="classroom"
                    v-validate="'required'"
                  >
                  </multiselect>
                  <p class="legenda-form">
                    • Nome da área de membros | Curso | Turma
                  </p>
                  <b-form-invalid-feedback
                    :state="!errors.has('club.classroom')"
                  >
                    Nenhuma Turma/Curso selecionada
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                  label="Selecione a Assinatura"
                  label-for="membership_id"
                  v-if="club.data.access == 'signature'"
                  data-anima="top"
                >
                  <multiselect
                    v-model="club.data.signature_ids"
                    :options="club.options"
                    :value="club.data.signature_ids"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    placeholder="Selecione a Assinatura"
                    label="text"
                    track-by="text"
                    selectLabel=""
                    deselectLabel=""
                    selectedLabel="✔️"
                    class="multi-select-input"
                    name="membership_id"
                    id="membership_id"
                    v-validate="'required'"
                  >
                  </multiselect>
                  <p class="legenda-form">
                    • Nome da área de membros | Assinatura
                  </p>
                  <b-form-invalid-feedback
                    :state="!errors.has('club.data.membership_id')"
                  >
                    Nenhuma assinatura selecionada
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
              <div v-else>
                <p class="legenda" data-anima="bottom">Sem itens para listar</p>
              </div>
            </div>
          </b-form>
          <div v-else class="dados-incompletos" data-anima="bottom">
            <h6>Atualize os dados cadastrais</h6>
            <p>
              Para adicionar a integração com o Club, é necessário que os dados
              do seu perfil estejam atualizados.
            </p>
            <p>
              Por gentileza atualize os dados da sua conta e retorne nessa etapa
              para continuar a integração.
            </p>
          </div>
        </div>
      </div>
    </section>

    <template v-slot:footer="{ cancel }">
      <BaseButton
        :disabled="loading"
        variant="secondary_outline"
        class="mr-4"
        @click="cancel"
      >
        Cancelar
      </BaseButton>

      <BaseButton
        variant="secondary"
        :disabled="loading"
        @click="adicionarClub"
        v-if="userOk && !newClub.flag"
      >
        Criar Liberação
      </BaseButton>
      <BaseButton
        variant="secondary"
        :disabled="loading || errors.has('club.subdomain')"
        @click="novoClub"
        v-if="userOk && newClub.flag"
      >
        Criar área de membros
      </BaseButton>

      <BaseButton
        variant="info3"
        :disabled="loading"
        @click="atualizarConta"
        v-if="!userOk"
      >
        Atualizar meus dados
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import Multiselect from "vue-multiselect";
import Cookies from "js-cookie";

// services:
import ClubService from "@/services/resources/ClubService";
const serviceClub = ClubService.build();

import IntegrationService from "@/services/resources/IntegrationService";
const serviceIntegration = IntegrationService.build();

export default {
  data() {
    return {
      loading: false,
      submit: false,
      dados_user: {},
      userOk: true,
      flag_novo: false,
      club: {
        data: {
          access: null,
          type: "club",
          product_id: null,
        },
        options_projetos: [{ value: null, text: "Selecione" }],
        site_id: "",
        options_conexao_tipo: [
          { value: null, text: "Selecione" },
          { value: "course_class", text: "Turmas" },
          { value: "signature", text: "Assinatura" },
        ],
        options_conexao: [],
        options: [],
      },
      newClub: {
        product: {
          id: "",
          name: "",
        },
        flag_name: "Criar área de membros",
        flag: false,
        name: "",
        course: "",
        subdomain: "",
        create_demo: false,
      },
      regrasLiberacao: [],
    };
  },
  components: {
    Multiselect,
  },
  props: {
    id_product: {
      type: Number,
    },
    name_product: {
      type: String,
    },
  },
  computed: {
    clubIntegration() {
      return this.$store.getters.userClub;
    },
  },
  methods: {
    loginClub() {
      this.$store.dispatch("clubLogin").catch(function(err) {});
    },
    // btn para atualizar os dados da conta
    atualizarConta() {
      open("/minha-conta");
      this.$bvModal.hide("modal-integracoes_club");
    },
    // criando um novo projeto no club
    novoClub() {
      this.$validator.validateAll("club").then((result) => {
        if (result) {
          this.submit = true;
          this.loading = true;
          const id_product = this.id_product;
          const name_product = this.name_product;

          let data = {
            project_name: `#${id_product} - ${this.newClub.name}`,
            course_title: name_product,
            create_demo: this.newClub.create_demo || false,
            subdomain: this.newClub.subdomain,
          };
          // informando o usuario sobre a demora na criação do projeto
          this.$bvToast.toast(
            "Iniciamos a criação da sua área de membros. Isso pode demorar um pouco",
            {
              title: "Voomp Play",
              variant: "info",
              autoHideDelay: 5000,
              appendToast: true,
            }
          );

          // iniciando a criação do projeto
          serviceClub
            .create(data)
            .then((response) => {
              if (response.success) {
                // fechando modal para não bloquear o fluxo do usuario
                // this.$bvModal.hide("modal-integracoes_club");

                this.$store
                  .dispatch("clubVerification")
                  .catch(function(err) {});

                this.$bvToast.toast(
                  "A sua área de membros foi criada com sucesso",
                  {
                    title: "Voomp Play",
                    variant: "success",
                    autoHideDelay: 5000,
                    appendToast: true,
                  }
                );

                this.flag_novo = true;

                this.toggleNewClub();
                this.clearFields();
                this.fetchProjetos();
              } else {
                this.$bvToast.toast(
                  "Houve algum erro ao criar a sua área de membros",
                  {
                    title: "Voomp Play",
                    variant: "danger",
                    autoHideDelay: 5000,
                    appendToast: true,
                  }
                );
              }
              this.loading = false;
              this.submit = false;
            })
            .catch((error) => {
              this.loading = false;
              this.submit = false;
              console.log(error);
            });
        }
      });
    },

    // adicinando novo item ao club
    adicionarClub() {
      this.submit = true;
      this.loading = true;
      this.club.data.product_id = this.id_product;
      if (this.club.data.access == "signature") {
        // separando names
        this.club.data.signature_names = [...this.club.data.signature_ids].map(
          (item) => item.text
        );

        // separando IDs
        this.club.data.signature_ids = [...this.club.data.signature_ids].map(
          (item) => item.value
        );
      } else {
        // Separando names
        this.club.data.course_class_names = [
          ...this.club.data.course_class_id,
        ].map((item) => item.text);

        // separando IDs
        this.club.data.course_class_ids = [
          ...this.club.data.course_class_id,
        ].map((item) => item.value);

        delete this.club.data.course_class_id;
      }

      this.$validator.validateAll("club").then((result) => {
        if (result) {
          serviceIntegration
            .create(this.club.data)
            .then((response) => {
              if (response.success) {
                this.$store
                  .dispatch("clubVerification")
                  .catch(function(err) {});
                this.$bvToast.toast("Regra de liberação criada com sucesso", {
                  title: "Voomp Play",
                  variant: "success",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
                this.$bvModal.hide("modal-integracoes_club");
                this.$emit("close");
                this.clearFields();
              } else {
                this.$bvToast.toast(
                  "Houve algum erro ao criar a Regra de liberação",
                  {
                    title: "Voomp Play",
                    variant: "danger",
                    autoHideDelay: 5000,
                    appendToast: true,
                  }
                );
              }
              this.loading = false;
              this.submit = false;
            })
            .catch((error) => {
              this.loading = false;
              this.submit = false;
              console.log(error);
            });
        } else {
          // console.log("erro aqui");
        }
      });
    },

    // evento change no select
    changeClub(event, flag = false) {
      if (this.club.data.access == "signature") {
        delete this.club.data.course_class_id;
      } else if (this.club.data.access == "course_class") {
        delete this.club.data.signature_ids;
      }

      this.fetchIntegrationClub(flag);
    },

    // puxando os itens do club
    fetchIntegrationClub(flag = false) {
      this.loading = true;
      let data = {
        type: "club",
        access: this.club.data.access,
        site_id: this.club.site_id,
      };

      serviceIntegration
        .search(data)
        .then((response) => {
          this.club.options = [];
          if (this.club.data.access == "signature") {
            response.forEach((item) => {
              this.club.options.push({
                value: item.signature_id,
                text: ` ${item.site_name} | ${item.signature_title}`,
              });
            });
          } else {
            response.forEach((item) => {
              this.club.options.push({
                value: item.course_class_id,
                text: ` ${item.site_name} | ${item.course_title} | ${item.course_class_title}`,
              });
            });
          }

          if (this.flag_novo && !flag) {
            this.flag_novo = false;
            // this.club.data.course_class_id = this.club.options[0];
          }
          if (!flag) {
            this.loading = false;
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    // flag club novo
    toggleNewClub() {
      this.newClub.flag = !this.newClub.flag;
      if (this.newClub.flag) this.newClub.flag_name = "Voltar para selecionar";
      if (!this.newClub.flag) this.newClub.flag_name = "Criar área de membros";
    },

    // limpa os campos / reseta
    clearFields() {
      this.club.data.access = null;
      this.club.options = [];
      this.club.site_id = null;
      this.club.data.course_class_id = [];
      this.club.data.signature_ids = null;
      this.newClub = {
        product: {
          id: "",
          name: "",
        },
        flag_name: "Criar área de membros",
        flag: false,
        name: "",
        course: "",
        subdomain: "",
        create_demo: false,
      };
    },

    changeProjeto(value) {
      this.club.site_id = value;
      this.club.data.access = null;
    },

    fetchProjetos() {
      this.loading = true;
      serviceClub
        .search()
        .then((response) => {
          this.$store.dispatch("clubVerification").catch(function(err) {});
          if (response.length) {
            this.club.options_projetos = [{ value: null, text: "Selecione" }];
            [...response].forEach((item) => {
              this.club.options_projetos.push({
                value: item.id,
                text: item.name,
              });
            });

            if (this.flag_novo) {
              this.club.site_id = null;
              this.club.site_id = this.club.options_projetos[
                this.club.options_projetos.length - 1
              ].value;

              this.club.data.access = "signature";
              this.changeClub(this.club.data.access, true);

              setTimeout(() => {
                this.club.data.access = "course_class";
                this.changeClub();
              }, 1000);
            }
          } else {
            this.$bvToast.toast(
              "Seja bem vindo ao Club, Vamos criar o seu 1º",
              {
                title: "Voomp Play",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              }
            );
            this.toggleNewClub();
            setTimeout(() => {
              if (document.querySelector("#project_name")) {
                document.querySelector("#project_name").focus();
              }
            }, 1000);
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.$bvToast.toast("Seja bem vindo ao Club, Vamos criar o seu 1º", {
            title: "Voomp Play",
            variant: "info",
            autoHideDelay: 5000,
            appendToast: true,
          });
          this.toggleNewClub();
          setTimeout(() => {
            if (document.querySelector("#project_name")) {
              document.querySelector("#project_name").focus();
            }
          }, 1000);
        })
        .finally(() => {
          if (!this.flag_novo) this.loading = false;
        });
    },

    // abrindo modal e setando valores
    openModal() {
      // this.dados_user = JSON.parse(Cookies.get("user"));
      this.dados_user = Object.assign({}, this.$store.state.user.user);
      if (
        this.dados_user.name &&
        this.dados_user.email &&
        this.dados_user.cellphone
      ) {
        this.userOk = true;
        // puxandos projetos
        this.fetchProjetos();
      } else {
        this.userOk = false;
      }

      // if(this.dados_user.name)
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
}
.title-inter {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 30px;
  color: $black;
}
.form-group + .form-group {
  margin-top: 15px;
}
/* // codigo de verificação g digital */
.codigo-verifica {
  max-width: 60%;
}
@media screen and (max-width: 768px) {
  .codigo-verifica {
    max-width: 100%;
  }
}
.codigo-verifica p {
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
  margin-bottom: 20px;
}
.sem-codigo {
  font-size: 14px;
  color: #333;
}
.sem-codigo:hover {
  font-weight: normal;
}
.grid-events {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 20px 0 10px;
}
.titulo-acao {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
}

.check-box .info-checkbox {
  font-size: 13px;
  font-weight: 500;
  margin-left: 5px;
  cursor: pointer;
  max-width: 250px;
  margin-top: 15px !important;
}
.sub-dominio {
  font-weight: normal;
  color: #81858e;
  font-size: 13px;
  line-height: 1.5;
  margin-top: 5px;
}
/* legenda */
.legenda {
  color: #81858e;
  font-size: 14px;
  margin: 0;
  margin-top: 20px;
  text-align: center;
}
.legenda-form {
  color: #81858e;
  font-size: 13px;
  margin: 0;
  position: absolute;
  top: 0;
  right: 0;
}

/* dados incompletos */
.dados-incompletos h6 {
  font-weight: 600;
  font-size: 16px;
}
.dados-incompletos p {
  color: #81858e;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 5px;
  font-weight: normal;
}

/* btn novo projeto */
.novo-projeto {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}

.action-club {
  display: grid;
}
</style>
